import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import { Logon } from '../api';
import * as config from '../config';

export function AppRegistro({ callback }) {
    const [explotacion, setExplotacion] = useState('');
    const [pwd, setPwd] = useState('');
    const [error, setError] = useState({ status: false, message: '' });
    const [isLoading, setIsLoading] = useState(false);

    const tExplotaciones = config.getAppExplotacionTable();

    const checkLogon = (e) => {
        e.preventDefault();

        if (explotacion === '') {
            setError({ status: true, message: 'Seleccione explotación' });
            return false;
        }

        if (pwd === '') {
            setError({ status: true, message: 'Escriba el código de verificación' });
            return false;
        }

        setIsLoading(true);

        const explotacion_data = config.APP_EXPLOTACIONES.find( item => item.id === parseInt(explotacion) );

        Logon(
            explotacion_data.user,
            pwd
        ).then((response) => {
            // console.log("RES=", response);
            callback(explotacion_data);
            setError({ status: false, message: '' })
        }).catch((error) => {
            // console.log("RES=", error);
            setError({ status: true, message: error.response_message })
        }).finally(() => {
            setIsLoading(false);
        })

        return false;
    }

    if (isLoading) {
        return (
            <Container>
                <div className='vh-100 d-flex flex-row justify-content-center align-items-center'>
                    <Spinner animation="border" role="status" className=''></Spinner>
                    <span className='mx-2'>Cargando datos...</span>
                </div>
            </Container>
        );
    }

    return (
        <Container fluid>
            <Row className='my-5 justify-content-center align-items-center'>
                <Col xs={2} className="">
                    <img src={process.env.PUBLIC_URL + 'logo512.png'} alt="P" className='img-fluid'/>
                </Col>
                <Col xs={7} className="fs-2">
                    Aplicación para la gestión de Partes de trabajo
                </Col>
            </Row>
            <hr class="w-75 mx-auto"></hr>
            <Row className='my-5 justify-content-md-center'>
                <Col md={8} className="">
                    <Form className="mx-auto" onSubmit={checkLogon}>
                        <div className='mb-5'>
                            <h1>Instalación</h1>
                            <p>
                                Esta es la primera vez que ejecutas la aplicación en este dispositivo.
                                Para que se descarguen las bases de datos y puedas trabajar debes seleccionar
                                tú explotación y escribir el código de autorización
                            </p>
                        </div>

                        <Form.Group controlId="formUSR">
                            <Form.Label>Explotación</Form.Label>
                            <Form.Select aria-label="Selecciona explotación" defaultValue={explotacion} onChange={(e) => setExplotacion(e.target.value)}>
                                <option value="">Selecciona explotación</option>
                                {tExplotaciones.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="formPWD" className="mt-2">
                            <Form.Label>Código autorización</Form.Label>
                            <Form.Control type="password" placeholder="Contraseña" onChange={(e) => setPwd(e.target.value)} />
                        </Form.Group>

                        <div className="text-end">
                            <Button variant="primary" type="submit" className="mt-3">
                                Acceder
                            </Button>
                        </div>

                        {error.status &&
                            <Alert className="mt-2" variant={'danger'}>
                                {error.message}
                            </Alert>
                        }

                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
