// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React, { useState } from 'react';
import { Stack, Row, Col, Button, Modal, Table, Alert } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faDownload } from '@fortawesome/free-solid-svg-icons';

import { Loading } from '../atoms/loading';
import { AppSync } from './app_sync';
import { PartesSync } from './partes_sync';
import * as config from '../config';
import moment from 'moment';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function Dashboard() {
    const [syncPartes, setSyncPartes] = useState(false);
    const [syncApp, setSyncApp] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const fecha_txt = moment(config.getLastSync()).fromNow();
    const num_partes = config.getPartesCount();
    const app_explotacion = config.getAppExplotacion();

    const syncpartes_callback = (status) => {
        setSyncPartes(false)
    }

    const syncdata_callback = (status) => {
        config.setLastSync(moment());
        setSyncApp(false)
    }

    if (syncPartes) {
        if (config.checkPartesFinalizados())
            return <PartesSync callback={syncpartes_callback} />
        else {
            setShowAlert(true);
        }
        setSyncPartes(false)
    }

    if (syncApp) {
        return <AppSync callback={syncdata_callback} tablas={config.tablas} />
    }

    return (
        <div>
            <h5>Inicio</h5>
            <p>
                Bienvenido a la aplicación de gestión de partes diarios.
            </p>
            <Table striped hover>
                <tbody>
                    <tr>
                        <th>Explotación</th>
                        <td>{app_explotacion.nombre}</td>
                    </tr>
                    <tr>
                        <th>Partes pendientes de sincornizar</th>
                        <td>{num_partes}</td>
                    </tr>
                    <tr>
                        <th>Ultima actualización de datos</th>
                        <td>{fecha_txt}</td>
                    </tr>
                </tbody>
            </Table>

            <Row className='my-5 text-center'>
                <Col>
                    <Button variant="secondary" onClick={() => setSyncApp(true)}>
                        <FontAwesomeIcon icon={faDownload} /> Descargar configuración
                    </Button>
                </Col>
                <Col>
                    <Button variant="primary" disabled={num_partes === 0} onClick={() => setSyncPartes(true)}>
                        <FontAwesomeIcon icon={faUpload} /> Enviar Partes de trabajo
                    </Button>
                </Col>
            </Row>

            {
                showAlert && (
                    <Row className="mt-3">
                        <Col>
                            <Alert variant="danger">
                                Hay partes de vehículo que no están finalizados. No se puede sincronizar.
                            </Alert>
                        </Col>
                    </Row>
                )
            }
        </div>


    )
}