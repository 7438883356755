// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React from 'react';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'

import { Row, Col, Table, Button, Alert, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { VehiculoHeader } from './vehiculo_header';
import { VehiculoBody } from './vehiculo_body';

import * as config from '../config';
import moment from 'moment';

let parte_original = undefined;
let parte_actual = undefined;
let parte_index = -1;

let body_index = -1;
let body_data = undefined;

let minutos_totales = 0;

// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
export function VehiculoDetalle() {
    const [isLoading, setIsLoading] = useState(true);
    const [showHeader, setShowHeader] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showAccion, setShowAccion] = useState(false);
    const [showFinalizarParte, setShowFinalizarParte] = useState(false);
    const [showDeleteAccion, setShowDeleteAccion] = useState(-1)
    const [showAlert, setShowAlert] = useState(false);
    const [showDeleteParte, setShowDeleteParte] = useState(false);


    const callback_header = (status, data) => {
        if (status === true) {
            parte_actual.header = { ...data }

            const vtipo = config.tVehiculosTipos.find(x => x.id == parte_actual.header.vehiculo_tipo_id);
            parte_actual.parte_tipo_id = vtipo.es_cttv ? config.PARTE_TIPO.CCTV : config.PARTE_TIPO.CAMION;
            save_click();
        } else {
            if ( JSON.stringify(parte_actual.header) === '{}' ) {
                navigate('/partes', { replace: true })
            }
        }
        setShowHeader(false);
    }

    const callback_cerrar_parte = (status, data) => {
        if (status === true) {
            parte_actual.parte_finalizado = true;
            parte_actual.header = { ...data };
            save_click();
            navigate('/partes', { replace: true })
        }

        setShowFinalizarParte(false);
    }

    const callback_body = (status, data) => {
        if (status === true) {
            parte_actual.body[body_index] = { ...data }
            save_click()
        }
        setShowAccion(false);
    }

    const editar_accion_click = (index) => {
        body_index = index;
        body_data = parte_actual.body[index];
        setShowAccion(true);
    }

    const delete_accion_click = (index) => {
        parte_actual.body.splice(index, 1)
        save_click();
        setShowDeleteAccion(-1);
    }

    const cancel_click = () => {
        if (JSON.stringify(parte_original) === JSON.stringify(parte_actual) === false) {
            setShowCancel(true)
        } else {
            navigate('/partes', { replace: true })
        }
    }

    const delete_parte_click = () => {
        if (parte_index !== -1) {
            config.tPartes.splice(parte_index, 1)
            config.setPartes(config.tPartes)
        }
        navigate('/partes', { replace: true })
    }

    /**
     * Siempre se pone como hora inicial la última que tengamos
     * En caso de ser continuar, también se copia la poblacion y la calle
     *
     * @param {*} es_continuar
     */

    const nuevo_click = (es_continuar) => {
        body_index = parte_actual.body.length;
        body_data = undefined;

        console.log("1 ", body_index)
        if (body_index > 0 ) {
            const last_item = parte_actual.body.length - 1;

            body_data = { ...config.parte_body_default }
            body_data.hora_inicial = parte_actual.body[last_item].hora_final
            body_data.hora_final = parte_actual.body[last_item].hora_final

            if ( es_continuar ) {
                body_data.poblacion_id = parte_actual.body[last_item].poblacion_id
                body_data.calle_id = parte_actual.body[last_item].calle_id
                body_data.esquina_id = 0
                body_data.sector = parte_actual.body[last_item].sector
                body_data.mantenimiento_tipo_id = 0
                body_data.camara_modelo_id = parte_actual.body[last_item].camara_modelo_id
            }
        }

        setShowAccion(true);
    }

    const save_click = () => {
        if (parte_index === -1) {
            config.tPartes.push(JSON.parse(JSON.stringify(parte_actual)));
            parte_index = config.tPartes.length - 1;
            parte_original = config.tPartes[parte_index];
        } else {
            config.tPartes[parte_index] = JSON.parse(JSON.stringify(parte_actual));
            parte_original = config.tPartes[parte_index];
        }

        config.setPartes(config.tPartes)
        setShowAlert(true)
        setTimeout(() => setShowAlert(false), 2000)
    }

    const print_operario_horas = (id, normal, extra) => {
        let txt = "-"
        if ( id != 0 ) {
            const nombre = config.getDataRowField(config.tUsuarios, id)
            const suma = parseFloat(normal) + parseFloat(extra)
            txt = `${nombre} (${suma}h)`
        }

        return txt
    }

    //
    //

    const navigate = useNavigate();
    const { indice, tipo_parte } = useParams();

    if (isLoading) {
        parte_original = undefined;
        parte_actual = undefined;
        parte_index = -1;

        body_index = -1;
        body_data = undefined;

        setIsLoading(false);
        return null
    }

    if (parte_actual === undefined) {
        if (indice === undefined) {
            parte_actual = JSON.parse(JSON.stringify(config.parte_default));
            parte_actual.parte_tipo_id = tipo_parte
            parte_index = -1;
            parte_original = JSON.parse(JSON.stringify(parte_actual));
        } else {
            parte_original = config.tPartes[indice];
            parte_actual = JSON.parse(JSON.stringify(parte_original));
            parte_index = indice;
        }
    }

    if (JSON.stringify(parte_actual.header) === '{}' || showHeader) {
        return <VehiculoHeader callback={callback_header} data={parte_actual.header} es_cerrar={false} minutos_trabajados={0} />
    }

    if (showAccion) {
        const vehiculo = config.getDataRowByID(config.tVehiculos, parte_actual.header.vehiculo_id)
        return <VehiculoBody callback={callback_body} tipo_parte={parte_actual.parte_tipo_id} vehiculo={vehiculo} vehiculo_como={parte_actual.header.vehiculo_tipo_id} data={body_data} />
    }

    if (showFinalizarParte) {
        return <VehiculoHeader callback={callback_cerrar_parte} data={parte_actual.header} es_cerrar={true} minutos_trabajados={minutos_totales} />
    }

    minutos_totales = 0;

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <b>Parte</b>
                    <Button className='float-end py-0' variant="success" size="sm" onClick={() => setShowHeader(true)}>Editar</Button>
                </div>
                <div className="card-body">
                    <Row>
                        <Col xs={12} md={6}>
                            <Table borderless size="sm" className='my-1'>
                                <tbody className=''>
                                <tr>
                                    <th>Fecha</th>
                                    <td>{moment(parte_actual.header.fecha).format("L")}</td>
                                </tr>
                                <tr>
                                    <th>Vehículo</th>
                                    <td>{config.getDataRowField(config.tVehiculos, parte_actual.header.vehiculo_id, 'matricula')}</td>
                                </tr>
                                <tr>
                                    <th>Tipo</th>
                                    <td>{config.getDataRowField(config.tParteTipos, parte_actual.parte_tipo_id)}</td>
                                </tr>
                                <tr>
                                    <th>Explotación</th>
                                    <td>{config.getDataRowField(config.tExplotaciones, parte_actual.header.explotacion_id)}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>

                        <Col xs={12} md={6}>
                            <Table borderless size="sm"  className='my-1'>
                                <tbody>
                                <tr>
                                    <th>Conductor</th>
                                    <td>{print_operario_horas(parte_actual.header.conductor_id, parte_actual.header.conductor_horas, parte_actual.header.conductor_extras)}</td>
                                </tr>
                                <tr>
                                    <th>Acomp #1</th>
                                    <td>{print_operario_horas(parte_actual.header.acomp1_id, parte_actual.header.acomp1_horas, parte_actual.header.acomp1_extras)}</td>
                                </tr>
                                <tr>
                                    <th>Acomp #2</th>
                                    <td>{print_operario_horas(parte_actual.header.acomp2_id, parte_actual.header.acomp2_horas, parte_actual.header.acomp2_extras)}</td>
                                </tr>
                                <tr>
                                    <th>Turno</th>
                                    <td>{config.getDataRowField(config.tTurnos, parte_actual.header.turno_id)}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='card my-3'>
                <div className='card-header'>
                    <b>Mantenimientos</b>
                </div>
                <div className="card-body">

                    <Table striped hover borderless responsive="lg" className='my-1'>
                        <thead>
                            <tr>
                                <th>Inicio</th>
                                <th>Final</th>
                                <th>Descripción</th>
                                <th className='text-center'>Acción</th>
                            </tr>
                        </thead>

                        <tbody className='align-middle'>
                            {parte_actual.body.map((item, index) => {
                                const h1 = moment(item.hora_inicial);
                                const h2 = moment(item.hora_final);

                                // La hora de la comida no cuenta para las horas trabajadas
                                if (item.mantenimiento_tipo_id != 29) {
                                    minutos_totales += h2.diff(h1, "minutes");
                                }

                                let accion_txt = config.getDataRowField(config.tMantAcciones, item.accion_id, 'nombre');
                                if (item.accion_id == 6) {
                                    const tmp = config.getDataRowField(config.tMantTipo, item.mantenimiento_tipo_id, 'nombre');
                                    accion_txt += ` (${tmp.toLowerCase()})`
                                }

                                return (
                                    <tr key={index} className='align-text-top'>
                                        <td>{moment(item.hora_inicial).format("LT")}</td>
                                        <td>{moment(item.hora_final).format("LT")}</td>
                                        <td>
                                            <span className='text-primary font-weight-bold'>{accion_txt}</span><br/>
                                            {config.getDataRowField(config.tPoblaciones, item.poblacion_id, 'nombre')}
                                        </td>
                                        <td className='text-center'>
                                            <Button className='me-1' variant="danger" size="sm" onClick={() => setShowDeleteAccion(index)}><FontAwesomeIcon icon={faTrashCan} /></Button>
                                            <Button className='me-1' variant="success" size="sm" onClick={() => editar_accion_click(index)}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                                        </td>
                                    </tr>
                                )
                            })}

                            {
                                parte_actual.body.length === 0 &&
                                <tr>
                                    <td colSpan={6} className="text-center py-4">No hay mantenimientos registrados</td>
                                </tr>
                            }
                        </tbody>
                    </Table>


                    <Row className='mt-2 align-items-center'>
                        <Col>
                            Horas trabajadas {config.formatMinutos(minutos_totales)}
                        </Col>
                        <Col className='text-end'>
                            <Button variant="primary" size="sm" className='m-1' onClick={() => nuevo_click(false)}>Nuevo</Button>
                            <Button variant="primary" size="sm" className='m-1' onClick={() => nuevo_click(true)}>Continuar</Button>
                        </Col>
                    </Row>

                </div>
            </div>

            <Row className="mt-3 text-center">
                <Col>
                    <Button variant="danger" className='m-1' onClick={() => setShowDeleteParte(true)}>Eliminar parte</Button>
                </Col>
                <Col>
                    <Button variant="primary" className='m-1' onClick={() => setShowFinalizarParte(true)}>Finalizar parte</Button>
                </Col>
                <Col>
                    <Button type="submit" variant="secondary" className='m-1' onClick={() => cancel_click()}>Cerrar</Button>
                </Col>
            </Row>

            {
                showAlert && (
                    <Row className="mt-3">
                        <Col>
                            <Alert variant="success">
                                Parte guardado correctamente
                            </Alert>
                        </Col>
                    </Row>
                )
            }

            <Modal show={showCancel} onHide={() => setShowCancel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancelar parte</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Hay cambios en el parte sin guardar ¿Desea cancelar las modificaciones y deshacer los cambios?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => navigate('/partes', { replace: true })}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowCancel(false)}>No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteAccion !== -1} onHide={() => setShowDeleteAccion(-1)}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar mantenimiento</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        Si eliminas la información esta ya no se podrá recuperar
                        ¿Estás seguro que quieres elminar el mantenimiento?
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => delete_accion_click(showDeleteAccion)}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowDeleteAccion(-1)}>No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteParte} onHide={() => setShowDeleteParte(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar parte</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Si eliminas la información esta ya no se podrá recuperar ¿Estás seguro que quieres elminar el parte?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => delete_parte_click()}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowDeleteParte(false)}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
