// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Row, Col, Form, Button, Modal } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es_ES from "date-fns/locale/es";


import * as config from '../config';
import moment from 'moment';


// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

let body_data = undefined;
let error_msg = '';
let por_horas = false;

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function OperarioBody({ callback, data, tipo_parte }) {
    const [showCancel, setShowCancel] = useState(false);
    const [showError, setShowError] = useState(false);
    const [h1, setH1] = useState(new Date());
    const [h2, setH2] = useState(new Date());
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        setH1(moment(body_data.hora_inicial).toDate())
        setH2(moment(body_data.hora_final).toDate())
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if ( !por_horas ) {
            h1.setHours(0,0,0);
            h2.setHours(23, 59, 59)    
        }

        body_data.hora_inicial = h1;
        body_data.hora_final = h2;

        // if ( body_data.accion_id == 6 ) {
        //     // Otros productivo
        //     if ( body_data.obs == '' ) {
        //         error_msg = "Es obligatorio rellenar las observaciones"
        //         setShowError(true)
        //         return false
        //     }
        // }

        callback(true, body_data);
        return false;
    }

    const cancel_click = () => {
        callback(false, null);
    }


    const accion_changed = (e) => {
        body_data.accion_id = e.target.value;
        setRefresh(refresh + 1);
    }

    if (refresh === 0) {
        body_data = undefined;
        error_msg = ''
        por_horas = false;
    }

    if (body_data === undefined) {
        if (data === undefined) {
            body_data = { ...config.parte_body_default }
            data = { ...body_data }
        } else {
            body_data = { ...data }
        }
    }

    por_horas = ( body_data.accion_id == 10 || body_data.accion_id == 7 )

    return (
        <Form className="mx-auto" onSubmit={handleSubmit}>

            {/* ACCION */}

            <div className='card my-3'>
                <div className='card-header'>
                    <b>Acción</b>
                </div>
                <div className='card-body'>

                    <Row>
                        <Form.Group as={Col} sm={12} className="m-2">
                            <Form.Select required value={body_data.accion_id} onChange={(e) => accion_changed(e)}>
                                <option value="">Selecciona acción</option>
                                {config.tMantAcciones.filter((item) => item.tipo_parte.includes(parseInt(tipo_parte))).map((item, index) => {
                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    {
                        // Otros - No Productivo
                        body_data.accion_id == 7 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-25">Tipo Mant</Form.Label>
                                    <Form.Select required defaultValue={body_data.mantenimiento_tipo_id} onChange={(e) => body_data.mantenimiento_tipo_id = e.target.value}>
                                        <option value="">Selecciona tipo</option>
                                        {config.tMantTipo.filter((item) => [13, 14, 30, 5].includes(item.id)).map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {
                        // Comun a todas las acciones
                        body_data.accion_id != 0 &&
                        
                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-25  align-self-start">Observaciones</Form.Label>
                                    <Form.Control as="textarea" rows={5} defaultValue={body_data.obs} onChange={(e) => body_data.obs = e.target.value} />
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                </div>
            </div>

            <div className='card'>
                <div className='card-header'>
                    <b>Tiempos</b>
                </div>
                <div className='card-body'>

                    <Row>
                        <Form.Group as={Col} sm={6} className="mb-2">
                            <Form.Label>Fecha inical</Form.Label>
                            <DatePicker
                                className='w-100 mt-1'
                                dateFormat={por_horas ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
                                locale={es_ES}
                                withPortal
                                showTimeSelect={por_horas}
                                timeIntervals={15}
                                selected={h1}
                                onChange={(fecha) => setH1(fecha)}
                                onFocus={e => e.target.blur()}>
                            </DatePicker>
                        </Form.Group>

                        <Form.Group as={Col} sm={6} className="mb-2">
                            <Form.Label>Fecha final</Form.Label>
                            <DatePicker
                                className='w-100 mt-1'
                                dateFormat={por_horas ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
                                locale={es_ES}
                                withPortal
                                showTimeSelect={por_horas}
                                timeIntervals={15}
                                selected={h2}
                                onChange={(fecha) => setH2(fecha)}
                                onFocus={e => e.target.blur()}>
                            </DatePicker>
                        </Form.Group>
                    </Row>

                </div>
            </div>
            

            {/* BOTONES */}

            <Row className="mt-1 text-center">
                <Col>
                    <Button variant="danger" onClick={() => cancel_click()}>Cancelar</Button>
                </Col>
                <Col>
                    <Button type="submit" variant="primary">Aceptar</Button>
                </Col>
            </Row>

            <Modal show={showCancel} onHide={() => setShowCancel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancelar</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Hay cambios en la información del parte ¿Desea cancelar las modificaciones y deshacer los cambios?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => callback(false, null)}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowCancel(false)}>No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showError} onHide={() => setShowError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ERROR</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{error_msg}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowError(false)}>OK</Button>
                </Modal.Footer>
            </Modal>
        </Form>
    )
}