// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React, { useState } from 'react';
import { Row, Col, Form, Button, Alert } from "react-bootstrap";

import * as config from '../config';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function OperarioLogon({ callback, posicion, operario_id }) {
    const [pwd, setPwd] = useState('')
    const [error, setError] = useState('')

    const checkOperarioLogon = (estado) => {
        if (estado) {
            if (pwd === '1111') {
                callback(true, posicion, operario_id)
                return
            } else {
                setError("Usuario o contraseña incorrecta")
                return
            }
        } else {
            callback(false, null, null)
        }
    }

    return (
        <Form noValidate className="mx-auto w-50">
            <Row className="mb-3 mt-2">
                <Col className='h5'>Acceso Usuario</Col>
            </Row>
            <Row className='mb-4'>
                <Col>
                    Para poder añadir o elminar personal del vehículo es necesario
                    escribibir su contraseña de acceso
                </Col>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col}>
                    <Form.Label>Usuario</Form.Label>
                    <Form.Control required type="text" defaultValue={config.getDataRowText(config.tUsuarios, operario_id)} disabled />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col}>
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control required type="password" defaultValue={pwd} onChange={(e) => setPwd(e.target.value)} />
                </Form.Group>
            </Row>
            <Row className="mt-5 text-center">
                <Col>
                    <Button variant="danger" onClick={() => checkOperarioLogon(false)}>Cancelar</Button>
                </Col>
                <Col>
                    <Button variant="primary" onClick={() => checkOperarioLogon(true)}>Continuar</Button>
                </Col>
            </Row>
            {error &&
                <Alert className="mt-4" variant={'danger'}>
                    {error}
                </Alert>
            }
        </Form>
    )
}