// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React from 'react';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'

import { Row, Col, Table, Button, Alert, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import { OperarioHeader } from './operario_header';
import { OperarioBody } from './operario_body';

import * as config from '../config';
import moment from 'moment';

let parte_original = undefined;
let parte_actual = undefined;
let parte_index = -1;

let body_index = -1;
let body_data = undefined;

let minutos_totales = 0;

// -------------------------------------------------------------------------------------------
// accion = '', 'cancelar', 'mantenmiento'
// -------------------------------------------------------------------------------------------
export function OperarioDetalle() {
    const [isLoading, setIsLoading] = useState(true);
    const [showCancel, setShowCancel] = useState(false)
    const [showAccion, setShowAccion] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [showDeleteParte, setShowDeleteParte] = useState(false)
    const [showDeleteAccion, setShowDeleteAccion] = useState(-1)
    const [showHeader, setShowHeader] = useState(false)

    const callback_header = (status, data) => {
        if (status === true) {
            parte_actual.header = { ...data }
            save_click();
        } else {
            if ( JSON.stringify(parte_actual.header) === '{}' ) {
                navigate('/partes', { replace: true })
            }
        }
        setShowHeader(false)
    }

    const callback_body = (status, data) => {
        if (status === true) {
            parte_actual.body[body_index] = { ...data }
            save_click();
        }
        setShowAccion(false);
    }

    const editar_accion_click = (index) => {
        body_index = index;
        body_data = parte_actual.body[index];
        setShowAccion(true);
    }

    const delete_accion_click = (index) => {
        parte_actual.body.splice(index, 1)
        save_click();
        setShowDeleteAccion(-1);
    }

    const cancel_click = () => {
        parte_actual.header.conductor_horas = config.round_medias_horas(minutos_totales / 60);
        save_click();

        if (JSON.stringify(parte_original) === JSON.stringify(parte_actual)) {
            navigate('/partes', { replace: true })
        } else {
            setShowCancel(true)
        }
    }

    const delete_parte_click = () => {
        if (parte_index !== -1) {
            config.tPartes.splice(parte_index, 1)
            config.setPartes(config.tPartes)
        }
        navigate('/partes', { replace: true })
    }

    const nuevo_click = () => {
        body_index = parte_actual.body.length;
        body_data = undefined;
        setShowAccion(true);
    }

    const save_click = (es_cerrar) => {
        if (parte_index === -1) {
            config.tPartes.push(JSON.parse(JSON.stringify(parte_actual)));
            parte_index = config.tPartes.length - 1;
            parte_original = config.tPartes[parte_index];
        } else {
            config.tPartes[parte_index] = JSON.parse(JSON.stringify(parte_actual));
            parte_original = config.tPartes[parte_index];
        }

        config.setPartes(config.tPartes)
        setShowAlert(true)
        setTimeout(() => setShowAlert(false), 2000)
    }

    //
    //

    const navigate = useNavigate();
    const { indice, tipo_parte } = useParams();

    if (isLoading === true) {
        parte_original = undefined;
        parte_actual = undefined;
        parte_index = -1;

        body_index = -1;
        body_data = undefined;

        setIsLoading(false);
        return null
    }

    if (parte_actual === undefined) {
        if (indice === undefined) {
            parte_actual = JSON.parse(JSON.stringify(config.parte_default));
            parte_actual.parte_tipo_id = tipo_parte
            parte_index = -1;
            parte_original = JSON.parse(JSON.stringify(parte_actual));
        } else {
            parte_original = config.tPartes[indice];
            parte_actual = JSON.parse(JSON.stringify(parte_original));
            parte_index = indice;
        }
    }

    if (JSON.stringify(parte_actual.header) === '{}' || showHeader) {
        return <OperarioHeader callback={callback_header} data={parte_actual.header} />
    }

    if (showAccion) {
        return <OperarioBody callback={callback_body} tipo_parte={parte_actual.parte_tipo_id} data={body_data} />
    }

    minutos_totales = 0;

    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <b>Parte de Operario</b>
                    <Button className='float-end py-0' variant="success" size="sm" onClick={() => setShowHeader(true)}>Editar</Button>
                </div>
                <div className="card-body">
                    <Row>
                        <Col xs={12} md={6}>
                            <Table borderless size="sm" className='my-1'>
                                <tbody>
                                <tr>
                                    <th>Fecha</th>
                                    <td>{moment(parte_actual.header.fecha).format("LL")}</td>
                                </tr>
                                <tr>
                                    <th>Explotación</th>
                                    <td>{config.getDataRowField(config.tExplotaciones, parte_actual.header.explotacion_id)}</td>
                                </tr>
                                <tr>
                                    <th>Conductor</th>
                                    <td>{config.getDataRowField(config.tUsuarios, parte_actual.header.conductor_id)}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='card my-3'>
                <div className='card-header'>
                    <b>Acciones</b>
                </div>
                <div className="card-body">

                    <Table striped hover className='my-1'>
                        <thead>
                            <tr>
                                <th>Inicio</th>
                                <th>Final</th>
                                <th>Acción</th>
                                <th className='text-center'>Editar</th>
                            </tr>
                        </thead>

                        <tbody className='align-middle'>
                            {parte_actual.body.map((item, index) => {
                                const h1 = moment(item.hora_inicial);
                                const h2 = moment(item.hora_final);

                                // Otros = 7, Descanso = 10
                                if (item.accion_id == 7 || item.accion_id == 10 ) {
                                    minutos_totales += h2.diff(h1, "minutes");
                                }

                                let accion_txt = config.getDataRowField(config.tMantAcciones, item.accion_id, 'nombre');
                                if (item.accion_id == 7) {
                                    const tmp = config.getDataRowField(config.tMantTipo, item.mantenimiento_tipo_id, 'nombre');
                                    accion_txt += ` (${tmp.toLowerCase()})`
                                }

                                return (
                                    <tr key={index}>
                                        <td>{moment(item.hora_inicial).format("DD/MM/yyyy HH:mm")}</td>
                                        <td>{moment(item.hora_final).format("DD/MM/yyyy HH:mm")}</td>
                                        <td>{accion_txt}</td>
                                        <td className='text-center'>
                                            <Button className='my-1 me-1' variant="danger" size="sm" onClick={() => setShowDeleteAccion(index)}><FontAwesomeIcon icon={faTrashCan} /></Button>
                                            <Button className='my-1 me-1' variant="success" size="sm" onClick={() => editar_accion_click(index)}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                                        </td>
                                    </tr>
                                )
                            })}
                            {
                                parte_actual.body.length === 0 &&
                                <tr>
                                    <td colSpan={4} className="text-center py-4">No hay acciones registradas</td>
                                </tr>
                            }
                        </tbody>
                    </Table>

                    <Row className='mt-3 align-items-center'>
                        <Col>
                            Horas trabajadas {config.formatMinutos(minutos_totales)}
                        </Col>
                        <Col className='text-end'>
                            <Button variant="primary" size="sm" className='m-1' onClick={() => nuevo_click(false)}>Nuevo</Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <Row className="mt-3 text-center">
                <Col>
                    <Button variant="danger" className='m-1' onClick={() => setShowDeleteParte(true)}>Eliminar parte</Button>
                </Col>
                <Col>
                    <Button type="submit" variant="secondary" className='m-1' onClick={() => cancel_click()}>Volver</Button>
                </Col>
            </Row>

            {
                showAlert && (
                    <Row className="mt-3">
                        <Col>
                            <Alert variant="success">
                                Parte guardado correctamente
                            </Alert>
                        </Col>
                    </Row>
                )
            }

            <Modal show={showCancel} onHide={() => setShowCancel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancelar parte</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Hay cambios en el parte sin guardar ¿Desea cancelar las modificaciones y deshacer los cambios?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => navigate('/partes', { replace: true })}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowCancel(false)}>No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteAccion !== -1} onHide={() => setShowDeleteAccion(-1)}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar mantenimiento</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        Si eliminas la información esta ya no se podrá recuperar
                        ¿Estás seguro que quieres elminar el mantenimiento?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => delete_accion_click(showDeleteAccion)}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowDeleteAccion(-1)}>No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteParte} onHide={() => setShowDeleteParte(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar parte</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Si eliminas la información esta ya no se podrá recuperar ¿Estás seguro que quieres elminar el parte?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => delete_parte_click()}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowDeleteParte(false)}>No</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
