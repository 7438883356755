import axios from 'axios';
import { Buffer } from 'buffer';
import * as config from './config';

export const API_URL = process.env.REACT_APP_API_URL;
const API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT;

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
export function CheckIsAuth() {
    const token_access = sessionStorage.getItem("token_access");

    if ( token_access === null || token_access === "undefined" ) {
        return false
    }

    const jwt = JSON.parse( Buffer.from(token_access.split(".")[1], 'base64') )
    const ahora = new Date();

    if ( jwt.exp > ahora.getTime() / 1000  ) {
         return true;
    }

    return false;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
export async function Logon(usr, pwd) {
    const server = axios.create({
        baseURL: API_URL,
        timeout: API_TIMEOUT
    })

    let response = {
        'status': true,
        'response_status': 200,
        'response_message': ''
    }

    return new Promise( (resolve, reject) => {
        server.post(
            '/token/',
            {
                username: usr,
                password: pwd
            }
        ).then( (response) => {
            // console.log("RES=", response);
            sessionStorage.setItem("token_access", response.data.access);
            // token_refresh = response.data.refresh;
            resolve( response ) 
        }).catch( (error) => {
            // console.log("RES (api)=", error);
            response.status = false;

            if ( error.response.status ) {
                if ( error.response.status === 401 ) {
                    response.response_message = 'Usuario o contraseña desconocida';
                } else {
                    const txt = `(${error.response.status}) - ${error.response.statusText}`
                    response.response_message = txt;
                }
            } else {
                response.response_message = 'No hay conexión con el servidor';
            }

            reject( response )
        })
    });
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
export async function DownloadDataTable(url_path, table_name) {
    const token = sessionStorage.getItem("token_access")

    const server = axios.create({
        baseURL: API_URL,
        timeout: API_TIMEOUT,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

    let response = {
        'status': true,
        'data': null
    }

    return new Promise( (resolve, reject) => {
        server.get(
            url_path

        ).then( (response) => {
            // console.log("RES=", response);
            // console.log(table_name, " size ", JSON.stringify(response.data).length);
            config.setDataTable(table_name, response.data );

            resolve( true ) 

        }).catch( (error) => {
            response.status = false;

            if ( error.response ) {
                if ( error.response.status === 401 ) {
                    response.response_message = 'Usuario o contraseña desconocida';
                } else {
                    const txt = `(${error.response.status}) - ${error.response.statusText}`
                    response.response_message = txt;
                }
            } else {
                response.response_message = error.message; // 'No hay conexión con el servidor';
            }

            reject( response )
        })
    });
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
export async function UploadData(url_path, data) {
    const token = sessionStorage.getItem("token_access")

    const server = axios.create({
        baseURL: API_URL,
        timeout: API_TIMEOUT,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

    let response = {
        'status': true,
        'data': null
    }

    return new Promise( (resolve, reject) => {
        server.post(
            url_path,
            { 'datos': data }
        ).then( (response_post) => {
            response.data = response_post.data
            resolve( response ) 
        }).catch( (error) => {
            response.status = false;

            if ( error.response ) {
                if ( error.response.status === 401 ) {
                    response.response_message = 'Usuario o contraseña desconocida';
                } else {
                    const txt = `(${error.response.status}) - ${error.response.statusText}`
                    response.response_message = txt;
                }
            } else {
                response.response_message = error.message; // 'No hay conexión con el servidor';
            }

            reject( response )
        })
    });
}
