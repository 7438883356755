// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'

import { Button, Modal } from "react-bootstrap";

import { Loading } from '../atoms/loading';
import * as config from '../config';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function AcercaDe() {
    const [showWarning, setShowWarning] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showUpdated, setShowUpdated] = useState(false);

    const delete_click = () => {
        setShowWarning(false)
        setShowLoading(true);
        config.deleteDataTables(callback_delete);
    }

    const callback_delete = (status) => {
        setShowLoading(false);
    }

    const reload_app = () => {
        setShowUpdated(false);
        // window.location.reload(true);
        window.location.replace(window.location.pathname);
    }

    if ( showLoading ) {
        return <Loading txt="Elminando información"/>
    }

    return (
        <div className='text-center'>
            <img src={process.env.PUBLIC_URL + 'logo512.png'} alt="P" className='my-4'/>
            <h5>Aplicación de partes diarios</h5>
            <p>{config.APP_VERSION}</p>

            <div className='row py-3'>
                <div className='col'>
                    <p>
                        Pulsar este botón para actualizar la aplicación a una nueva versión.
                        No habrá pérdida de datos aunque recomendamos hacer la actualización después de
                        subir todos los partes.
                    </p>
                    <Button className="mx-1" variant="success" size="sm" onClick={() => setShowUpdated(true)}>Actualizar</Button>
                </div>
                <div className='col'>
                    <p>
                        Pulsar este botón para borrar toda la información de la aplicación y volver a comenzar
                        como si fuera la primera vez que se ejecuta. Al iniciar volverá a preguntar cual es
                        la explotación principal.
                    </p>
                    <Button className="mx-1"  variant="danger" size="sm" onClick={() => setShowWarning(true)}>Resetear</Button>
                </div>
            </div>

            <Modal show={showWarning} onHide={() => setShowWarning(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Resetar aplicación</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Si resteas la aplicación se perdará toda la información almacenada en este equipo.</p>
                    <p className='text-danger'><strong>Incluidos los partes no sincornizados</strong></p>
                    <p>¿Estás seguro que quieres borrar todo?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => delete_click() }>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowWarning(false)}>No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUpdated} onHide={() => setShowUpdated(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Actualizar aplicación</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        Recuerda que para finalizar la actualización
                        es necesario cerrar esta ventana y volver a poner en marcha la aplicación
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" style={{ width: 100 }} onClick={() => setShowUpdated(false) }>No</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => reload_app()}>Si</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}