// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";

import { CheckIsAuth, UploadData } from '../api'
import { AppLogon } from './app_logon'
import * as config from '../config'

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function PartesSync({ callback }) {
    const [estado, setEstado] = useState({ resultado: 0, error: '', showButtons: false });
    const [isAuth, setIsAuth] = useState(CheckIsAuth());

    useEffect(() => {
        if (isAuth === true) {
            loadData();
        }
    }, [isAuth])

    const loadData = async (item) => {
        const data = config.getPartes()
        let resultado = 1;
        let error_txt = '';

        UploadData(
            '/partes-sync/',
            data
        ).then((response) => {
            resultado = 2
            config.setPartes([])
        }).catch((error) => {
            error_txt = error.response_message
            resultado = 3;
        }).finally(() => {
            setEstado({ resultado: resultado, error: error_txt, showButtons: true })
        })
    }

    const reload = () => {
        setEstado({ resultado: 0, error: '', showButtons: false });
    }

    const callback_logon = (estado) => {
        setIsAuth(estado);
    }

    const MySpinner = () => {
        if (estado.resultado === 0) {
            return (
                <div className='d-flex flex-row justify-content-start align-items-center'>
                    <span>X</span>
                    <span className='mx-2'>Partes</span>
                </div>
            )
        }

        if (estado.resultado === 1) {
            return (
                <div className='d-flex flex-row justify-content-start align-items-center'>
                    <Spinner animation="border" role="status" size="sm" className=''></Spinner>
                    <span className='mx-2'>Partes</span>
                </div>
            )
        }

        if (estado.resultado === 2) {
            return (
                <Alert variant="success">
                    Partes exportados correctamente
                </Alert>
            )
        }

        if (estado.resultado === 3) {
            return (
                <Alert variant="danger">
                    No se han podido exportar los partes<br/>
                    <br/>
                    {estado.error}
                </Alert>
            )
        }

    }

    if (isAuth === false) {
        return (
            <AppLogon callback={callback_logon} />
        );
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1>Exportación de partes</h1>

                    <div className='mt-4'>
                        <MySpinner ></MySpinner>
                    </div>

                    {
                        estado.showButtons && (
                            <div className='my-3'>
                                {estado.resultado === 2 ? (
                                    <Button variant="success" onClick={() => callback(true)}>Continuar</Button>
                                ) : (
                                    <Button variant="danger" onClick={() => reload()}>Reintentar</Button>
                                )}
                            </div>
                        )
                    }
                </Col>
            </Row>

        </Container>
    )
}
