// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useState } from "react";

import { AppRegistro } from './views/app_registro';
import { AppSync } from "./views/app_sync";
import { Layout } from "./views/layout";
import { Dashboard } from './views/dashboard';
import { PartesListado } from './views/partes_listado';
import { VehiculoDetalle } from "./views/vehiculo_detalle";
import { OperarioDetalle } from "./views/operario_detalle";
import { AcercaDe } from './views/acerca_de';

import { Loading } from './atoms/loading';
import * as config from './config';

import moment from 'moment';
import 'moment/locale/es';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
let explotacion_usuario = undefined;
let app_checkDT = { 'comprobado': false, 'estado': false}
let app_loadDT = false

moment.locale('es');

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
function  App() {
    const [appEstado, setAppEstado] = useState(0);

    const callback_registro = (explotacion_data) => {
        config.setAppExplotacion(explotacion_data);
        setAppEstado(appEstado + 1);
    }

    const callback_checkDataTables = (estado) => {
        app_checkDT = { 'comprobado': true, 'estado': estado}
        setAppEstado(appEstado + 1);
    }

    const callback_sync = () => {
        app_checkDT = { 'comprobado': true, 'estado': true};
        config.setLastSync(moment());
        setAppEstado(appEstado + 1);
    }

    const callback_loaddt = (estado) => {
        app_loadDT = true
        setAppEstado(appEstado + 1);
    }

    //
    // comprobamos que nos hemos registrado con alguna explotacions
    //
    explotacion_usuario = config.getAppExplotacion();
    if (!explotacion_usuario) {
        return <AppRegistro callback={callback_registro} />
    }

    //
    // Comprobamos que todas las tablas estan descargadas. En caso de tenerlas todas
    // miramos que no haga mas de una semana que no se actualizan
    //

    if ( !app_checkDT.comprobado ) {
        config.checkDataTables( callback_checkDataTables )
        return <Loading />
    }

    if ( !app_checkDT.estado ) {
        return <AppSync callback={callback_sync} tablas={config.tablas} />
    } else {
        let last_sync = config.getLastSync();
        if (moment().diff(last_sync, "days") > 30 * 1) {
            return <AppSync callback={callback_sync} tablas={config.tablas} />
        }
    }

    //
    // Cargamos las tablas una sola vez
    //
    if ( !app_loadDT ) {
        config.loadDataTables(callback_loaddt);
        return <Loading />
    }   

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="partes" element={<PartesListado />} />
                    <Route path="vehiculo/nuevo/:tipo_parte" element={<VehiculoDetalle />} />
                    <Route path="vehiculo/detalle/:indice" element={<VehiculoDetalle />} />
                    <Route path="operario/nuevo/:tipo_parte" element={<OperarioDetalle />} />
                    <Route path="operario/detalle/:indice" element={<OperarioDetalle />} />
                    <Route path="acercade" element={<AcercaDe />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
