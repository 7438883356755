// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom'

import { Button, Table } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faLock } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import * as config from '../config'

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function PartesVehiculo() {
    const navigate = useNavigate();
    const data = config.tPartes;

    const editar_click = (index) => {
        navigate("/vehiculo/detalle/" + index, { replace: true });
    }

    const nuevo_click = () => {
        navigate('/vehiculo/nuevo/' + 1, { replace: true });
    }

    const print_operario_horas = (item, oper) => {
        let res = "-";
        if ( item.header[oper + "_id"] != 0) {
            res = item.header[oper + "_horas"] + "h + " + item.header[oper + "_extras"] + "h"
        }
        return res
    }

    return (
        <div className="card mb-3">
            <div className='card-header'>
                Listado de partes Vehículo
            </div>
            <div className="card-body">

                <Table striped hover borderless className='my-3'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Vehículo</th>
                            <th>Operarios</th>
                            <th>Horas</th>
                            <th className='text-center'>Editar</th>
                        </tr>
                    </thead>

                    <tbody className='align-middle'>
                        {data.map((item, index) => {
                            if (item.parte_tipo_id != config.PARTE_TIPO.OPERARIO) {
                                return (
                                    <tr key={index} className='align-text-top'>
                                        <td>
                                            <span className='text-primary'>{moment(item.header.fecha).format("L")}</span>
                                            <br />{config.getDataRowField(config.tExplotaciones, item.header.explotacion_id)}
                                        </td>
                                        <td>
                                            <span className='text-primary'>{config.getDataRowField(config.tVehiculos, item.header.vehiculo_id, 'matricula')}</span>
                                            <br />{config.getDataRowField(config.tParteTipos, item.parte_tipo_id, 'nombre')}
                                            <br />{config.getDataRowField(config.tTurnos, item.header.turno_id)}
                                        </td>
                                        <td>
                                            <span className='text-primary'>{config.getDataRowField(config.tUsuarios, item.header.conductor_id)}</span>
                                            <br />{config.getDataRowField(config.tUsuarios, item.header.acomp1_id)}
                                            <br />{config.getDataRowField(config.tUsuarios, item.header.acomp2_id)}
                                        </td>
                                        <td>
                                            {print_operario_horas(item, 'conductor')}
                                            <br />{print_operario_horas(item, 'acomp1')}
                                            <br />{print_operario_horas(item, 'acomp2')}
                                        </td>
                                        <td className='text-center'>
                                            <Button variant="success" onClick={() => editar_click(index)}><FontAwesomeIcon icon={faPenToSquare} size="xs" /></Button>
                                            { item.parte_finalizado && <div className='my-2 text-success'><FontAwesomeIcon icon={faLock} /></div> }
                                        </td>
                                    </tr>
                                )
                            }
                        })}

                        {
                            data.length === 0 &&
                            <tr>
                                <td colSpan={8} className="text-center py-4">No hay partes que mostrar</td>
                            </tr>
                        }
                    </tbody>
                </Table>

                <div className='text-end'>
                    <Button variant="success" onClick={() => nuevo_click()}>Nuevo parte</Button>
                </div>
            </div>
        </div>
    )
}

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function PartesOperario() {
    const navigate = useNavigate();
    const data = config.tPartes;

    const editar_click = (index) => {
        navigate("/operario/detalle/" + index, { replace: true });
    }

    const nuevo_click = () => {
        navigate('/operario/nuevo/3', { replace: true });
    }

    return (
        <div className="card">
            <div className='card-header'>
                Listado de partes Operario
            </div>
            <div className="card-body">

                <Table striped hover borderless className='my-3'>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Operario</th>
                            <th>#</th>
                            <th className='text-center'>Editar</th>
                        </tr>
                    </thead>

                    <tbody className='align-middle'>
                        {data.map((item, index) => {
                            if (item.parte_tipo_id == config.PARTE_TIPO.OPERARIO) {
                                return (
                                    <tr key={index}>
                                        <td>{moment(item.header.fecha).format("L")}</td>
                                        <td>{config.getDataRowField(config.tUsuarios, item.header.conductor_id)}</td>
                                        <td>{item.body.length}</td>
                                        <td className='text-center'>
                                            <Button variant="success" onClick={() => editar_click(index)}><FontAwesomeIcon icon={faPenToSquare} size="xs" /></Button>
                                        </td>
                                    </tr>
                                )
                            }
                        })}

                        {
                            data.length === 0 &&
                            <tr>
                                <td colSpan={4} className="text-center py-4">No hay partes que mostrar</td>
                            </tr>
                        }
                    </tbody>
                </Table>

                <div className='text-end'>
                    <Button variant="success" onClick={() => nuevo_click()}>Nuevo parte</Button>
                </div>
            </div>
        </div>
    )
}

export function PartesListado() {
    return (
        <div>
            <PartesVehiculo />
            <PartesOperario />
        </div>
    );
}