// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Stack, Row, Col, Form, Button, Modal } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es_ES from "date-fns/locale/es";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { BuscadorCalles } from '../atoms/buscador_calles';
import { BuscadorMunicipios } from '../atoms/buscador_municipios';

import * as config from '../config';
import moment from 'moment';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

let body_data = undefined;
let error_msg = '';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function VehiculoBody({ callback, tipo_parte, vehiculo, vehiculo_como, data }) {
    const [showCancel, setShowCancel] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showMunicipio, setShowMunicipio] = useState(0);
    const [showCalles, setShowCalles] = useState(0);
    const [showDatePicker, setShowDatePicker] = useState(0);
    const [showTiempos, setShowTiempos] = useState(0)
    const [Horario, setHorario] = useState({ h1: new Date(), h2: new Date() })
    const [refresh, setRefresh] = useState(0);

    const cubas_14 = { 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' }
    const cubas_13 = { 0: '0%', 33: '33%', 66: '66%', 100: '100%' }

    const checkInt = (value) => {
        const resultado = Number.parseInt(value);
        if (Number.isNaN(resultado)) {
            return 0;
        }
        return resultado;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        //
        // Esto lo hago porque me escriben letras 'o' como si fueran ceros
        // y cuando quieren decir 'cero' algunos ponen vacío
        //
        body_data.imb_lim = checkInt(body_data.imb_lim);
        body_data.reja_lim = checkInt(body_data.reja_lim);
        body_data.mlreja_lim = checkInt(body_data.mlreja_lim);

        // pozos
        body_data.pozo_insp = checkInt(body_data.pozo_insp);
        body_data.pozo_lim = checkInt(body_data.pozo_lim);
        body_data.mlred_lim = checkInt(body_data.mlred_lim);

        // Elemento Singular
        body_data.id_esing = checkInt(body_data.id_esing);
        body_data.esing_insp = checkInt(body_data.esing_insp);
        body_data.esing_lim = checkInt(body_data.esing_lim);

        if (body_data.accion_id == 1) {
            // Imbornales
            if (body_data.imb_lim == 0 && body_data.reja_lim == 0 && body_data.mlreja_lim == 0) {
                error_msg = "Has seleccionado 0 Imbornales, 0 Rejas y 0 Metros limpiados"
                setShowError(true)
                return false
            }
        }

        if (body_data.accion_id == 2) {
            // Caso especial: Vehiculo = Pértiga comprobar también los imbornales
            if ( vehiculo_como == config.VEHICULO_PERTIGA ) {
                if ( body_data.imb_lim == 0 && body_data.mlreja_lim == 0 && body_data.pozo_insp == 0 && body_data.mlred_lim == 0) {
                    error_msg = "Has seleccionado 0 Imbornales, 0 Metros de reja, 0 Pozos y 0 metros de red"
                    setShowError(true)
                    return false
                }
            }

            // Pozos
            if (body_data.pozo_insp == 0 && body_data.pozo_lim == 0 && body_data.mlred_lim == 0) {
                error_msg = "Has seleccionado 0 Pozos inspeccionados, 0 Pozos limpiados y 0 metros"
                setShowError(true)
                return false
            }
        }

        if (body_data.accion_id == 3) {
            // Elementos Singulares
            if (body_data.esing_insp == 0 && body_data.esing_lim == 0) {
                error_msg = "Has seleccionado 0 elementos inspeccionados, 0 elementos limpiados"
                setShowError(true)
                return false
            }
        }

        if (body_data.accion_id == 4) {
            // Carga de agua
            body_data.mantenimiento_tipo_id = 4;
            if (body_data.carga_cuba == 0) {
                error_msg = "Seleccionado 0% en Carg de Agua"
                setShowError(true)
                return false
            }
        }

        if (body_data.accion_id == 21) {
            // Vaciado agua/residuos
            body_data.mantenimiento_tipo_id = 21;
            if (body_data.carga_residuo == 0) {
                error_msg = "Seleccionado 0% en Vaciado de Residuos"
                setShowError(true)
                return false
            }
        }

        if (body_data.accion_id == 6) {
            // Otros productivo
            body_data.mantenimiento_tipo_id = 5;
            if (body_data.obs == '') {
                error_msg = "Es obligatorio rellenar las observaciones"
                setShowError(true)
                return false
            }
        }

        if (body_data.poblacion_id == 0) {
            error_msg = "Es obligatorio seleccionar un municipio"
            setShowError(true)
            return false
        }

        if (body_data.mantenimiento_tipo_id == 3) {
            // CORRECTIVO
            if (body_data.calle_id == 0) {
                error_msg = "En un acción correctiva es obligatorio especificar la calle"
                setShowError(true)
                return false
            }
        }

        if (showTiempos === 0) {
            setShowDatePicker(2);
            return false
        }

        body_data.hora_inicial = Horario.h1;
        body_data.hora_final = Horario.h2;

        callback(true, body_data);
        return false;
    }

    const cancel_click = () => {
        if (data === undefined || config.dictionaryEqual(data, body_data) === false) {
            setShowCancel(true)
        } else {
            callback(false, null);
        }
    }

    const accion_changed = (e) => {
        body_data.accion_id = e.target.value;
        setShowDatePicker(1)
    }

    const slider_changed = (value, key) => {
        body_data[key] = value;
        setRefresh(refresh + 1);
    }

    const mantenimientoTipo_changed = (e) => {
        body_data.mantenimiento_tipo_id = e.target.value;
        setRefresh(refresh + 1);
    }

    const setHora = (hora) => {
        if (showDatePicker === 1) {
            setHorario({ ...Horario, h1: hora })
        } else {
            setHorario({ ...Horario, h2: hora })
            setShowTiempos(1);
        }
        setShowDatePicker(0);
    }

    const callback_municipio = (status, municipio_id) => {
        if (status) {
            body_data.poblacion_id = municipio_id;
            body_data.calle_id = 0
            body_data.esquina_id = 0
        }

        setShowMunicipio(0)
    }

    const callback_calle = (status, calle_id) => {
        if (status) {
            if (showCalles === 1) {
                body_data.calle_id = calle_id
            } else {
                body_data.esquina_id = calle_id
            }
        }

        setShowCalles(0)
    }


    if (refresh === 0) {
        body_data = undefined;
        error_msg = ''

        if (body_data === undefined) {
            if (data === undefined) {
                // Es nuevo desde cero
                const ahora = config.redondeaFechaHora(moment())
                body_data = { ...config.parte_body_default }
                body_data.hora_inicial = ahora.toDate();
                body_data.hora_final = ahora.toDate();
                data = { ...body_data }
            } else if (data.mantenimiento_tipo_id === 0) {
                // Es continuar
                body_data = { ...data }
            } else {
                // Es editar
                body_data = { ...data }
                setShowTiempos(2)
            }
        }

        setHorario({ h1: new Date(body_data.hora_inicial), h2: new Date(body_data.hora_final) })
        setRefresh(refresh + 1);
        return null;
    }

    let poblacion_txt = ""
    if (body_data.poblacion_id !== 0) {
        poblacion_txt = config.getDataRowField(config.tPoblaciones, body_data.poblacion_id, 'nombre')
    }

    let calle_txt = ""
    if (body_data.calle_id !== 0) {
        calle_txt = config.getDataRowField(config.tCalles, body_data.calle_id, 'tipo_via')
            + '. '
            + config.getDataRowField(config.tCalles, body_data.calle_id, 'nombre_via')
    }

    let esquina_txt = ""
    if (body_data.esquina_id !== 0) {
        esquina_txt = config.getDataRowField(config.tCalles, body_data.esquina_id, 'nombre_via')
    }

    if (showMunicipio > 0) {
        return <BuscadorMunicipios callback={callback_municipio}></BuscadorMunicipios>
    }

    if (showCalles > 0) {
        return <BuscadorCalles callback={callback_calle} poblacion_id={body_data.poblacion_id}></BuscadorCalles>
    }

    if (showDatePicker > 0) {
        //
        // 0 => Hora inicial, 1 => Hora Final
        //
        const yesterday = moment().add(-1, "day").hour(0).minute(0).second(0)
        const tomorrow = moment().add(1, "day").hour(23).minute(59).second(59)

        let ahora = (showDatePicker === 1) ? moment(Horario.h1) : moment(Horario.h2);
        ahora = config.redondeaFechaHora(ahora)

        return <DatePicker
            className='w-100 mt-1'
            dateFormat="dd/MM/yyyy HH:mm"
            locale={es_ES}
            showTimeSelect
            withPortal
            timeIntervals={15}
            selected={ahora.toDate()}

            onChange={(fecha) => setHora(fecha)}
            onCalendarClose={(fecha) => setHora(fecha)}
            onKeyDown={(f) => setShowDatePicker(0)}

            startOpen="true"
            minDate={yesterday.toDate()}
            maxDate={tomorrow.toDate()}>
        </DatePicker>
    }

    return (
        <Form className="mx-auto" onSubmit={handleSubmit}>

            {/* ACCION */}

            <div className='card'>
                <div className='card-header'>
                    <b>Acción</b>
                </div>
                <div className='card-body'>

                    <Row>
                        <Form.Group as={Col} sm={12} className="m-2">
                            <Form.Select required value={body_data.accion_id} onChange={(e) => accion_changed(e)}>
                                <option value="">Selecciona acción</option>
                                {config.tMantAcciones.filter((item) => item.tipo_parte.includes(parseInt(tipo_parte))).map((item, index) => {
                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    {
                        // Imbornales
                        body_data.accion_id == 1 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Tipo Mant</Form.Label>
                                    <Form.Select required defaultValue={body_data.mantenimiento_tipo_id} onChange={(e) => body_data.mantenimiento_tipo_id = e.target.value}>
                                        <option value="">Selecciona tipo</option>
                                        {config.tMantTipo.filter((item) => [1, 2, 3].includes(item.id)).map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Imbornales</Form.Label>
                                    <Form.Control required type="text" defaultValue={body_data.imb_lim} onChange={(e) => body_data.imb_lim = e.target.value} />
                                </Stack>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Rejas</Form.Label>
                                    <Form.Control required type="text" defaultValue={body_data.reja_lim} onChange={(e) => body_data.reja_lim = e.target.value} />
                                </Stack>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Metros lineales reja</Form.Label>
                                    <Form.Control required type="text" defaultValue={body_data.mlreja_lim} onChange={(e) => body_data.mlreja_lim = e.target.value} />
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {
                        // Pozos
                        body_data.accion_id == 2 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Tipo Mant</Form.Label>
                                    <Form.Select required defaultValue={body_data.mantenimiento_tipo_id} onChange={(e) => body_data.mantenimiento_tipo_id = e.target.value}>
                                        <option value="">Selecciona tipo</option>
                                        {config.tMantTipo.filter((item) => [1, 2, 3].includes(item.id)).map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>

                            {vehiculo_como == config.VEHICULO_PERTIGA &&
                                <Form.Group as={Col} sm={12} className="m-2">
                                    <Stack direction="horizontal" gap={3}>
                                        <Form.Label className="w-50">Imbornales inspeccionados</Form.Label>
                                        <Form.Control required type="text" defaultValue={body_data.imb_lim} onChange={(e) => body_data.imb_lim = e.target.value} />
                                    </Stack>
                                </Form.Group>
                            }
                            {vehiculo_como == config.VEHICULO_PERTIGA &&
                                <Form.Group as={Col} sm={12} className="m-2">
                                    <Stack direction="horizontal" gap={3}>
                                        <Form.Label className="w-50">Metros lineales reja inspeccionados</Form.Label>
                                        <Form.Control required type="text" defaultValue={body_data.mlreja_lim} onChange={(e) => body_data.mlreja_lim = e.target.value} />
                                    </Stack>
                                </Form.Group>
                            }

                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Pozos Inspeccionados</Form.Label>
                                    <Form.Control required type="text" defaultValue={body_data.pozo_insp} onChange={(e) => body_data.pozo_insp = e.target.value} />
                                </Stack>
                            </Form.Group>

                            {tipo_parte === config.PARTE_TIPO.CAMION &&
                                <Form.Group as={Col} sm={12} className="m-2">
                                    <Stack direction="horizontal" gap={3}>
                                        <Form.Label className="w-50">Pozos Limpiados</Form.Label>
                                        <Form.Control required type="text" defaultValue={body_data.pozo_lim} onChange={(e) => body_data.pozo_lim = e.target.value} />
                                    </Stack>
                                </Form.Group>
                            }
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Metros de red inspeccionados</Form.Label>
                                    <Form.Control required type="text" defaultValue={body_data.mlred_lim} onChange={(e) => body_data.mlred_lim = e.target.value} />
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {
                        // Elementos Singulares
                        body_data.accion_id == 3 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Tipo Mant</Form.Label>
                                    <Form.Select required defaultValue={body_data.mantenimiento_tipo_id} onChange={(e) => body_data.mantenimiento_tipo_id = e.target.value}>
                                        <option value="">Selecciona tipo</option>
                                        {config.tMantTipo.filter((item) => [1, 2, 3].includes(item.id)).map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Elemento</Form.Label>
                                    <Form.Select defaultValue={body_data.id_esing} onChange={(e) => body_data.id_esing = e.target.value}>
                                        <option value="">Selecciona elemento</option>
                                        {config.tElementoSingular.map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Inspeccionados</Form.Label>
                                    <Form.Control type="text" required defaultValue={body_data.esing_insp} onChange={(e) => body_data.esing_insp = e.target.value} />
                                </Stack>
                            </Form.Group>

                            {tipo_parte === config.PARTE_TIPO.CAMION &&
                                <Form.Group as={Col} sm={12} className="m-2">
                                    <Stack direction="horizontal" gap={3}>
                                        <Form.Label className="w-50">Limpiados</Form.Label>
                                        <Form.Control type="text" required defaultValue={body_data.esing_lim} onChange={(e) => body_data.esing_lim = e.target.value} />
                                    </Stack>
                                </Form.Group>
                            }
                        </Row>
                    }

                    {
                        // Carga Cuba
                        body_data.accion_id == 4 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Tipo Mant</Form.Label>
                                    <Form.Select required value={body_data.mantenimiento_tipo_id} onChange={(e) => mantenimientoTipo_changed(e)}>
                                        {config.tMantTipo.filter((item) => [4].includes(item.id)).map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Carga de agua</Form.Label>
                                    <Slider
                                        className='mx-3'
                                        min={0} max={100}
                                        marks={(vehiculo.carga_agua === '1/3') ? cubas_13 : cubas_14}
                                        step={25}
                                        defaultValue={body_data.carga_cuba}
                                        onChange={(value) => slider_changed(value, "carga_cuba")}
                                    />
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {
                        // Vaciado Residuos
                        body_data.accion_id == 21 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Tipo Mant</Form.Label>
                                    <Form.Select required value={body_data.mantenimiento_tipo_id} onChange={(e) => mantenimientoTipo_changed(e)}>
                                        {config.tMantTipo.filter((item) => [21].includes(item.id)).map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Vaciado de Residuos</Form.Label>
                                    <Slider
                                        className='mx-3'
                                        min={0} max={100}
                                        marks={(vehiculo.residuos === '1/3') ? cubas_13 : cubas_14}
                                        step={25}
                                        defaultValue={body_data.carga_residuo}
                                        onChange={(value) => slider_changed(value, "carga_residuo")}
                                    />
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {
                        // Limpieza depósito
                        body_data.accion_id == 5 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Tipo Mant</Form.Label>
                                    <Form.Select required defaultValue={body_data.mantenimiento_tipo_id} onChange={(e) => body_data.mantenimiento_tipo_id = e.target.value}>
                                        <option value="">Selecciona tipo</option>
                                        {config.tMantTipo.filter((item) => [27].includes(item.id)).map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {
                        // Otros Productivo
                        body_data.accion_id == 6 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Tipo Mant</Form.Label>
                                    <Form.Select required defaultValue={body_data.mantenimiento_tipo_id} onChange={(e) => body_data.mantenimiento_tipo_id = e.target.value}>
                                        <option value="">Selecciona tipo</option>
                                        {config.tMantTipo.filter((item) => [5, 8, 11, 12, 13, 22, 23, 28, 29].includes(item.id)).map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {tipo_parte == config.PARTE_TIPO.CCTV && body_data.accion_id != 0 &&
                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Cámara</Form.Label>
                                    <Form.Select required defaultValue={body_data.camara_modelo_id} onChange={(e) => body_data.camara_modelo_id = e.target.value}>
                                        <option value="">Selecciona cámara</option>
                                        {config.tCamaraModelo.map((item, index) => {
                                            return <option key={index} value={item.id}>{item.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {
                        // Comun a todas las acciones
                        body_data.accion_id != 0 &&

                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50">Galia OT</Form.Label>
                                    <Form.Control type="text" defaultValue={body_data.galia_ot} onChange={(e) => body_data.galia_ot = e.target.value} />
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                    {
                        // Comun a todas las acciones
                        body_data.accion_id != 0 &&
                        <Row className='m-3'>
                            <Form.Group as={Col} sm={12} className="m-2">
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Label className="w-50  align-self-start">Observaciones</Form.Label>
                                    <Form.Control as="textarea" rows={5} defaultValue={body_data.obs} onChange={(e) => body_data.obs = e.target.value} />
                                </Stack>
                            </Form.Group>
                        </Row>
                    }

                </div>
            </div>

            {/* LOCALIZACIÓN */}

            <div className='card my-3'>
                <div className='card-header'>
                    <b>Localización</b>
                </div>
                <div className='card-body'>
                    <Row>
                        <Form.Group as={Col} sm={6} className="mb-2">
                            <Form.Label>Población</Form.Label>
                            <Form.Control required type="text" readOnly value={poblacion_txt} onClick={() => setShowMunicipio(1)} ></Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} sm={6} className="mb-2">
                            <Form.Label>Calle</Form.Label>
                            <Form.Control type="text" readOnly value={calle_txt} onClick={() => setShowCalles(1)} ></Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} sm={6} className="mb-2">
                            <Form.Label>Esquina</Form.Label>
                            <Form.Control type="text" readOnly value={esquina_txt} onClick={() => setShowCalles(2)} ></Form.Control>
                        </Form.Group>

                    </Row>
                </div>
            </div>

            {showTiempos > 0 &&
                <div className='card'>
                    <div className='card-header'>
                        <b>Tiempos</b>
                    </div>
                    <div className='card-body'>

                        <Row className='m-3'>
                            <Form.Group as={Col} className="mb-2">
                                <Form.Label>Hora inical</Form.Label>
                                <DatePicker
                                    className='w-100 mt-1'
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    locale={es_ES}
                                    showTimeSelect
                                    withPortal
                                    timeIntervals={15}
                                    selected={Horario.h1}
                                    onChange={(fecha) => setHorario({ ...Horario, h1: fecha })}
                                    onFocus={e => e.target.blur()}>
                                </DatePicker>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-2">
                                <Form.Label>Hora final</Form.Label>
                                <DatePicker
                                    className='w-100 mt-1'
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    locale={es_ES}
                                    showTimeSelect
                                    withPortal
                                    timeIntervals={15}
                                    selected={Horario.h2}
                                    onChange={(fecha) => setHorario({ ...Horario, h2: fecha })}
                                    onFocus={e => e.target.blur()}>
                                </DatePicker>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-2">
                                <Form.Label>Resultado</Form.Label>
                                <Form.Control type="text" readOnly rows={5} value={config.printHorasMinutos(Horario.h1, Horario.h2)} />
                            </Form.Group>
                        </Row>
                    </div>
                </div>
            }

            {/* BOTONES */}

            <Row className="mt-5 text-center">
                <Col>
                    <Button variant="danger" onClick={() => cancel_click()}>Cancelar</Button>
                </Col>
                <Col>
                    <Button type="submit" variant="primary" autoFocus={showTiempos === 1 && showDatePicker === 0}>Aceptar</Button>
                </Col>
            </Row>

            <Modal show={showCancel} onHide={() => setShowCancel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancelar</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Hay cambios en la información del parte ¿Desea cancelar las modificaciones y deshacer los cambios?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => callback(false, null)}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowCancel(false)}>No</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showError} onHide={() => setShowError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>ERROR</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>{error_msg}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowError(false)}>OK</Button>
                </Modal.Footer>
            </Modal>
        </Form>
    )
}
