// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form, Button, Modal } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es_ES from "date-fns/locale/es";

import { OperarioLogon } from '../atoms/operario_logon'
import * as config from '../config';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

const horas = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8];

let header_data = undefined;
let header_original = undefined;
let selected_vehiculo = {};

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function VehiculoHeader({ callback, data, es_cerrar, minutos_trabajados}) {
    const [estado, setEstado] = useState(0)
    const [showOperarioLogon, setShowOperarioLogon] = useState([]);
    const [showCancel, setShowCancel] = useState(false);
    const [fecha, setFecha] = useState(new Date())

    const ref_conductor_horas = useRef(null);
    const ref_acomp1_horas = useRef(null);
    const ref_acomp2_horas = useRef(null);

    const ref_conductor_extras = useRef(null);
    const ref_acomp1_extras = useRef(null);
    const ref_acomp2_extras = useRef(null);

    const ref_conductor_1a = useRef(null);
    const ref_acomp1_1a = useRef(null);
    const ref_acomp2_1a = useRef(null);

    const explotacion_predeterminada = config.getAppExplotacion();

    useEffect(() => {
        ref_conductor_1a.current.checked = header_data.conductor_1a;
        ref_acomp1_1a.current.checked = header_data.acomp1_1a;
        ref_acomp2_1a.current.checked = header_data.acomp2_1a;

        if ( es_cerrar ) {
            calcular_horas();
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        header_data.fecha = fecha;

        header_data.conductor_1a = ref_conductor_1a.current.checked;
        header_data.conductor_horas = ref_conductor_horas.current.value;
        header_data.conductor_extras = ref_conductor_extras.current.value;

        header_data.acomp1_1a = ref_acomp1_1a.current.checked;
        header_data.acomp1_horas = ref_acomp1_horas.current.value;
        header_data.acomp1_extras = ref_acomp1_extras.current.value;

        header_data.acomp2_1a = ref_acomp2_1a.current.checked;
        header_data.acomp2_horas = ref_acomp2_horas.current.value;
        header_data.acomp2_extras = ref_acomp2_extras.current.value;

        header_data.explotacion_origen_id = explotacion_predeterminada.id;

        callback(true, header_data);
        return false;
    }

    const cancel_click = () => {
        if (config.dictionaryEqual(header_original, header_data) === false) {
            setShowCancel(true)
        } else {
            callback(false, null);
        }
    }

    const vehiculo_onChange = (e) => {
        const vehiculo_id = parseInt(e.target.value);
        header_data.vehiculo_id = vehiculo_id;

        selected_vehiculo = config.tVehiculos.find(x => x.id === vehiculo_id);
        header_data.vehiculo_tipo_id = selected_vehiculo.vehiculo_tipo;
        setEstado(estado + 1);
    }

    const vehiculo_tipo_onChange = (e) => {
        header_data.vehiculo_tipo_id = e.target.value
        setEstado(estado + 1);
    }

    const callback_operarioLogon = (estado, posicion, operario_id) => {
        const campo = ['conductor_id', 'acomp1_id', 'acomp2_id']

        if (estado) {
            header_data[campo[posicion]] = operario_id;
        }

        setShowOperarioLogon([])
    }

    const primera_salida_change = (e, operario, ref) => {
        header_data[operario + "_1a"] = ref.current.checked
        calcular_horas();
    }

    // const calcular_horas = () => {
    //     const minutos_8horas = 60*8;
    //     const minutos_horas = minutos_trabajados % minutos_8horas;
    //     const minutos_extras = minutos_trabajados - minutos_8horas;

    //     let horas = config.round_medias_horas(minutos_horas / 60);
    //     let extras = 0;

    //     if ( minutos_extras > 0 ) {
    //         extras =  config.round_medias_horas(minutos_extras / 60 );
    //     }

    //     ref_conductor_horas.current.value = ( header_data.conductor_1a && horas < 3 ) ? 3 : horas;
    //     ref_conductor_extras.current.value = extras;

    //     if ( header_data.acomp1_id != 0 ) {
    //         ref_acomp1_horas.current.value = ( header_data.acomp1_1a && horas < 3 ) ? 3 : horas;
    //         ref_acomp1_extras.current.value = extras;
    //     }
    //     if ( header_data.acomp2_id != 0 ) {
    //         ref_acomp2_horas.current.value = ( header_data.acomp2_1a && horas < 3 ) ? 3 : horas;
    //         ref_acomp2_extras.current.value = extras;
    //     }
    // }

    const calcular_horas = () => {
        let horas = config.round_medias_horas(minutos_trabajados / 60);

        ref_conductor_horas.current.value = horas;
        //ref_conductor_extras.current.value = extras;

        if ( header_data.acomp1_id != 0 ) {
            ref_acomp1_horas.current.value = horas;
            //ref_acomp1_extras.current.value = extras;
        }
        if ( header_data.acomp2_id != 0 ) {
            ref_acomp2_horas.current.value = horas;
            // ref_acomp2_extras.current.value = extras;
        }
    }

    if (showOperarioLogon.length) {
        const [posicion, operario_id] = showOperarioLogon;
        return <OperarioLogon callback={callback_operarioLogon} operario_id={operario_id} posicion={posicion} />
    }



    if (estado === 0) {
        header_data = undefined;
        header_original = undefined;
        setEstado(1);
        return null
    }

    if (header_data === undefined) {
        if (JSON.stringify(data) === '{}') {
            header_data = { ...config.parte_header_default }
            header_data.explotacion_id = explotacion_predeterminada.id
        } else {
            header_data = { ...data }
        }
        header_original = { ...header_data }
    }

    return (
        <Form className="mx-auto" onSubmit={handleSubmit}>
            <div className='card'>
                <div className='card-header'>
                    <b>Vehículo</b>
                </div>
                <div className="card-body">

                    <Row className="mb-3">
                        <Form.Group as={Col} sm={3} controlId="fromFecha" className='my-1'>
                            <Form.Label>Fecha</Form.Label>
                            <DatePicker
                                className='w-100 mt-1'
                                dateFormat="dd/MM/yyyy"
                                locale={es_ES}
                                selected={fecha}
                                onChange={(value) => setFecha(value)}
                                disabled={es_cerrar}
                                >
                            </DatePicker>
                            <Form.Control.Feedback type="invalid">
                                Selecciona una fecha
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm={3} className='my-1'>
                            <Form.Label>Vehículo</Form.Label>
                            <Form.Select required defaultValue={header_data.vehiculo_id} onChange={(e) => vehiculo_onChange(e)} disabled={es_cerrar}>
                                <option value="">Selecciona vehículo</option>
                                {config.tVehiculos.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.matricula}</option>
                                })}
                            </Form.Select>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm={3} className='my-1'>
                            <Form.Label>Utilizado como</Form.Label>
                            <Form.Select required value={header_data.vehiculo_tipo_id} onChange={(e) => vehiculo_tipo_onChange(e)} disabled={es_cerrar}>
                                <option value="">Tipo de vehículo</option>
                                {config.tVehiculosTipos.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                })}
                            </Form.Select>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm={3} className='my-1'>
                            <Form.Label>Zona de trabajo</Form.Label>
                            <Form.Select required defaultValue={explotacion_predeterminada.id} onChange={(e) => header_data.explotacion_id = e.target.value} disabled={es_cerrar}>
                                {config.tExplotaciones.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} sm={3} className='my-1'>
                            <Form.Label>Turno</Form.Label>
                            <Form.Select required defaultValue={header_data.turno_id} onChange={(e) => header_data.turno_id = e.target.value} disabled={es_cerrar}>
                                <option value="">Selecciona turno</option>
                                {config.tTurnos.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.nombre}</option>
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={3} className='my-1'>
                            <Form.Label>Km</Form.Label>
                            <Form.Control required type="text" defaultValue={header_data.km} onChange={(e) => header_data.km = e.target.value} disabled={es_cerrar}/>
                        </Form.Group>

                        {selected_vehiculo.es_grupo &&
                            <Form.Group as={Col} sm={3} className='my-1'>
                                <Form.Label>Horas grupo</Form.Label>
                                <Form.Control required type="text" defaultValue={header_data.horas_grupo} onChange={(e) => header_data.horas_grupo = e.target.value} />
                            </Form.Group>
                        }
                        {selected_vehiculo.es_bomba &&
                            <Form.Group as={Col} sm={3} className='my-1'>
                                <Form.Label>Horas bomba</Form.Label>
                                <Form.Control required type="text" defaultValue={header_data.horas_bomba} onChange={(e) => header_data.horas_bomba = e.target.value} />
                            </Form.Group>
                        }
                        {selected_vehiculo.es_depresor &&
                            <Form.Group as={Col} sm={3} className='my-1'>
                                <Form.Label>Horas depresor</Form.Label>
                                <Form.Control required type="text" defaultValue={header_data.horas_depresor} onChange={(e) => header_data.horas_depresor = e.target.value} disabled={es_cerrar}/>
                            </Form.Group>
                        }
                    </Row>
                </div>
            </div>

            <div className='card mt-4'>
                <div className='card-header'>
                    <b>Personal</b>
                </div>
                <div className="card-body">

                    <Row className="mb-3">
                        <Form.Group as={Col} className='my-1'>
                            <Form.Label>Conductor</Form.Label>
                            <Form.Select required defaultValue={header_data.conductor_id} onChange={(e) => setShowOperarioLogon([0, e.target.value])}  disabled={es_cerrar}>
                                <option value="">Selecciona conductor</option>
                                {config.tUsuarios.map((item, index) => (<option key={index} value={item.id}>{item.nombre}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={1} className='my-1'>
                            <Form.Label>1ª Salida</Form.Label>
                            <Form.Check
                                ref={ref_conductor_1a}
                                className='text-center'
                                onChange={(e) => primera_salida_change(e, 'conductor', ref_conductor_1a)} 
                                disabled={!es_cerrar}>
                            </Form.Check>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Horas</Form.Label>
                            <Form.Select ref={ref_conductor_horas} defaultValue={header_data.conductor_horas} onChange={(e) => header_data.conductor_horas = e.target.value} disabled={!es_cerrar}>
                                {horas.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Extra</Form.Label>
                            <Form.Select ref={ref_conductor_extras} defaultValue={header_data.conductor_extras} onChange={(e) => header_data.conductor_extras = e.target.value} disabled={!es_cerrar}>
                                {horas.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Dietas</Form.Label>
                            <Form.Select defaultValue={header_data.conductor_dietas} onChange={(e) => header_data.conductor_dietas = e.target.value} disabled={!es_cerrar}>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} className='my-1'>
                            <Form.Label>Acomp#1</Form.Label>
                            <Form.Select defaultValue={header_data.acomp1_id} onChange={(e) => setShowOperarioLogon([1, e.target.value])} disabled={es_cerrar}>
                                <option value="">Selecciona acompañante</option>
                                {config.tUsuarios.map((item, index) => (<option key={index} value={item.id}>{item.nombre}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={1} className='my-1'>
                            <Form.Label>1ª Salida</Form.Label>
                            <Form.Check
                                ref={ref_acomp1_1a}
                                className='text-center'
                                onChange={(e) => primera_salida_change(e, 'acomp1', ref_acomp1_1a)} 
                                disabled={!es_cerrar}>
                            </Form.Check>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Horas</Form.Label>
                            <Form.Select ref={ref_acomp1_horas} defaultValue={header_data.acomp1_horas} onChange={(e) => header_data.acomp1_horas = e.target.value} disabled={!es_cerrar}>
                                {horas.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Extra</Form.Label>
                            <Form.Select ref={ref_acomp1_extras} defaultValue={header_data.acomp1_extras} onChange={(e) => header_data.acomp1_extras = e.target.value} disabled={!es_cerrar}>
                                {horas.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Dietas</Form.Label>
                            <Form.Select defaultValue={header_data.acomp1_dietas} onChange={(e) => header_data.acomp1_dietas = e.target.value} disabled={!es_cerrar}>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} className='my-1'>
                            <Form.Label>Acomp#2</Form.Label>
                            <Form.Select defaultValue={header_data.acomp2_id} onChange={(e) => setShowOperarioLogon([2, e.target.value])} disabled={es_cerrar}>
                                <option value="">Selecciona acompañante</option>
                                {config.tUsuarios.map((item, index) => (<option key={index} value={item.id}>{item.nombre}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={1} className='my-1'>
                            <Form.Label>1ª Salida</Form.Label>
                            <Form.Check
                                ref={ref_acomp2_1a}
                                className='text-center'
                                onChange={(e) => primera_salida_change(e, 'acomp2', ref_acomp2_1a)} 
                                disabled={!es_cerrar}>
                            </Form.Check>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Horas</Form.Label>
                            <Form.Select ref={ref_acomp2_horas} defaultValue={header_data.acomp2_horas} onChange={(e) => header_data.acomp2_horas = e.target.value} disabled={!es_cerrar}>
                                {horas.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Extra</Form.Label>
                            <Form.Select ref={ref_acomp2_extras} defaultValue={header_data.acomp2_extras} onChange={(e) => header_data.acomp2_extras = e.target.value} disabled={!es_cerrar}>
                                {horas.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} sm={2} className='my-1'>
                            <Form.Label>Dietas</Form.Label>
                            <Form.Select defaultValue={header_data.acomp2_dietas} onChange={(e) => header_data.acomp2_dietas = e.target.value} disabled={!es_cerrar}>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mt-5 text-center">
                        <Col>
                            <Button variant="danger" onClick={() => cancel_click()}>Cancelar</Button>
                        </Col>
                        <Col>
                            <Button type="submit" variant="primary">Continuar</Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal show={showCancel} onHide={() => setShowCancel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancelar</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Hay cambios en la información del parte ¿Desea cancelar las modificaciones y deshacer los cambios?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => callback(false, null)}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowCancel(false)}>No</Button>
                </Modal.Footer>
            </Modal>
        </Form>
    )
}
