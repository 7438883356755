// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Stack, Row, Col, Form, Button, Modal } from "react-bootstrap";

import * as config from '../config';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function Layout() {
    return (
        <Container fluid className='px-0'>
            <Navbar bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={process.env.PUBLIC_URL + '/logo512.png'} alt="P" width={32}/>
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link href="/">Inicio</Nav.Link>
                        <Nav.Link href="/partes">Partes</Nav.Link>
                        <Nav.Link href="/acercade">Acerca de</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>

            <Container className='py-4'>
                <Outlet />
            </Container>

            <Row className='fixed-bottom bg-light px-3 py-2 small'>
                <Col>Aplicación de partes diarios</Col>
                <Col className='text-end'>Versión {config.APP_VERSION}</Col>
            </Row>
        </Container>
    )
}