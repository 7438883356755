import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Logon } from '../api';
import * as config from '../config';

export function AppLogon({ callback }) {
    const [pwd, setPwd] = useState('');
    const [error, setError] = useState({ status: false, message: '' });
    const [isLoading, setIsLoading] = useState(false);

    const checkLogon = (e) => {
        e.preventDefault();

        if (pwd === '') {
            setError({ status: true, message: 'Usuario o contraseña desconocida' });
            return false;
        }

        setIsLoading(true);

        Logon(
            app_explotacion.user,
            pwd
        ).then((response) => {
            // console.log("RES=", response);
            callback(true);
            setError({ status: false, message: '' })
        }).catch((error) => {
            console.log("RES=", error);
            if (error.response_status === 200) {
                setError({ status: true, message: error.response_message });
            } else if (error.response_status === 401) {
                setError({ status: true, message: 'No hay conexión con el servidor' })
            } else {
                const txt = `(${error.response.status}) - ${error.response.statusText}`
                setError({ status: true, message: txt })
            }
        }).finally(() => {
            setIsLoading(false);
        })

        return true;
    }

    const app_explotacion = config.getAppExplotacion();

    return (
        <Container fluid className=''>
            <Row className='my-5 justify-content-md-center'>
                <Col md={8} lg={4} className="">
                    <Form className="mx-auto" onSubmit={checkLogon}>
                        <div className='mb-5'>
                            <h1>Autorización</h1>
                            <p>
                                Escribe el código de acceso de la exploción para poder sincornizar
                                la información con la base de datos central
                            </p>
                        </div>

                        <Form.Group controlId="formUSR">
                            <Form.Label>Explotación</Form.Label>
                            <Form.Control type="text" placeholder="Escribe tu usuario" disabled defaultValue={app_explotacion.nombre} />
                        </Form.Group>

                        <Form.Group controlId="formPWD" className="mt-2">
                            <Form.Label>Código de acceso</Form.Label>
                            <Form.Control type="password" placeholder="Código" onChange={(e) => setPwd(e.target.value)} />
                        </Form.Group>

                        <div className="text-end">
                            <Button variant="primary" type="submit" className="mt-3">
                                Acceder
                            </Button>
                        </div>

                        {error.status &&
                            <Alert className="mt-2" variant={'danger'}>
                                {error.message}
                            </Alert>
                        }
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
