import React, { useState } from 'react';
import { Container, Stack, Row, Col, Form, Button, ListGroup, Alert, Spinner, Modal } from "react-bootstrap";

import * as config from '../config';


export function BuscadorCalles({ callback, poblacion_id }) {
    const [filtro, setFiltro] = useState('')
    const [seletedID, setSelectedID] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault();
        return false
    }

    const cancel_click = () => {
        callback(false, null);
    }

    const aceptar_click = () => {
        if (seletedID !== 0) {
            callback(true, seletedID);
        }
    }

    const item_click = (value) => {
        if (seletedID === value) {
            callback(true, value);
        } else {
            setSelectedID(value)
        }
    }
    const datos = config.tCalles.filter(
        (item) => item.nombre_via.toLowerCase().includes(filtro.toLowerCase()) === true
    ).filter(
        (item) => item.poblacion_id === parseInt(poblacion_id)
    )

    return (
        <div>

            <h5 className='mb-4'>Buscador de calles</h5>
            <Form className="mx-2" onSubmit={handleSubmit}>
                <Form.Group as={Col} sm={12} className="mb-2">
                    <Stack direction="horizontal" gap={3}>
                        <Form.Control type="text" onChange={(e) => setFiltro(e.target.value)} ></Form.Control>
                        <Button type="submit" variant="success">Filtrar</Button>
                    </Stack>
                </Form.Group>
            </Form>

            <h5 className='my-4'>Resultados</h5>
            <ListGroup>
                {datos.map((item, index) => {
                    return (
                        <ListGroup.Item key={index}
                            onClick={() => item_click(item.id)}
                            active={item.id === seletedID}>
                            {item.tipo_via}. {item.nombre_via}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            {
                datos.length === 0 && (
                    <Row><Col className='my-5 text-center'>No hay resultados</Col></Row>
                )
            }

            <Row className="m-3 text-center">
                <Col>
                    <Button variant="danger" onClick={() => cancel_click()}>Cancelar</Button>
                </Col>
                <Col>
                    <Button type="submit" variant="primary" disabled={seletedID === 0} onClick={() => aceptar_click()}>Aceptar</Button>
                </Col>
            </Row>
        </div>
    );
}