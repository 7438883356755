import React from 'react';
import { Container, Spinner } from "react-bootstrap";

export function Loading({txt}) {
    return (
        <Container>
            <div className='vh-100 d-flex flex-row justify-content-center align-items-center'>
                <Spinner animation="border" role="status" className=''></Spinner>
                <span className='mx-2'>{txt}</span>
            </div>
        </Container>
    );
}