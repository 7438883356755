// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React, { useState } from 'react';
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";

import { CheckIsAuth, DownloadDataTable } from '../api'
import { AppLogon } from './app_logon'

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function AppSync({ callback, tablas }) {
    const [isAuth, setIsAuth] = useState(CheckIsAuth());
    const [index, setIndex] = useState(0);
    const [allOk, setAllOk] = useState(true);

    const reload = () => {
        setAllOk(true);
        setIndex(0);
    }

    const loadData = async (item) => {
        // console.log("I= ", item)
        // setTimeout( () => { tablas[index].estado = 3; setIndex( index+1 )}, 1000);

        DownloadDataTable(
            tablas[index].api,
            tablas[index].tabla
        ).then((response) => {
            // console.log("RES (loaddata)=", response)
            tablas[index].estado = 2
        }).catch((error) => {
            // console.log("ERR (loaddata)=", error)
            tablas[index].error = error.response_message
            tablas[index].estado = 3
            setAllOk(false)
        }).finally(() => {
            setIndex(index + 1)
        })
    }

    const callback_logon = async (estado) => {
        setIsAuth(estado);
    }

    const MySpinner = (item) => {
        if (item.estado === 0) {
            return (
                <div className='d-flex flex-row justify-content-start align-items-center'>
                    <span>X</span>
                    <span className='mx-2'>{item.nombre}</span>
                </div>
            )
        }

        if (item.estado === 1) {
            return (
                <div className='d-flex flex-row justify-content-start align-items-center'>
                    <Spinner animation="border" role="status" size="sm" className=''></Spinner>
                    <span className='mx-2'>{item.nombre}</span>
                </div>
            )
        }

        if (item.estado === 2) {
            return (
                <div className='d-flex flex-row justify-content-start align-items-center'>
                    OK
                    <span className='mx-2'>{item.nombre}</span>
                </div>
            )
        }

        if (item.estado === 3) {
            return (
                <div>
                    <div className='d-flex flex-row justify-content-start align-items-center'>
                        KO
                        <span className='mx-2'>{item.nombre}</span>
                    </div>
                    <div className='text-danger text-small mb-2'>
                        {item.error}
                    </div>
                </div>
            )
        }

    }

    if (isAuth === false) {
        return (
            <AppLogon callback={callback_logon} />
        );
    }

    if (index < tablas.length) {
        tablas[index].estado = 1;
        loadData(index)
    }

    return (
        <Container fluid>
            <Row className='my-5 justify-content-center align-items-center'>
                <Col xs={2} className="">
                    <img src={process.env.PUBLIC_URL + 'logo512.png'} alt="P" className='img-fluid' />
                </Col>
                <Col xs={7} className="fs-2">
                    Aplicación para la gestión de Partes de trabajo
                </Col>
            </Row>

            <hr class="w-75 mx-auto"></hr>

            <Row className='my-5 justify-content-md-center'>
                <Col md={8} className="">
                    <h1>Importando datos</h1>

                    {tablas.map((item, index) => (
                        <div key={index}>
                            {MySpinner(item)}
                        </div>
                    ))}

                    <div className='my-3'>
                        {allOk ? (
                            <Button variant="success" onClick={() => callback(true)}>Continuar</Button>
                        ) : (
                            <Button variant="danger" onClick={() => reload()}>Reintentar</Button>
                        )}
                    </div>

                </Col>
            </Row>

        </Container >
    )
}
