// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Spinner, Modal } from "react-bootstrap";

import { OperarioLogon } from '../atoms/operario_logon'
import * as config from '../config';
import moment from 'moment';

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

let header_data = undefined;
let header_original = undefined;

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function OperarioHeader({ callback, data }) {
    const [estado, setEstado] = useState(0)
    const [showOperarioLogon, setShowOperarioLogon] = useState([]);
    const [showCancel, setShowCancel] = useState(false);

    const handleSubmit = (e) => {
        const form = e.currentTarget

        e.preventDefault();
        // header_data.fecha = fecha;
        callback(true, header_data);
        return false;
    }

    const cancel_click = () => {
        if (config.dictionaryEqual(header_original, header_data) === false) {
            setShowCancel(true)
        } else {
            callback(false, null);
        }
    }

    const callback_operarioLogon = (estado, posicion, operario_id) => {
        const campo = ['conductor_id', 'acomp1_id', 'acomp2_id']

        if (estado) {
            header_data[campo[posicion]] = operario_id;
        }

        setShowOperarioLogon([])
    }

    if (showOperarioLogon.length) {
        const [posicion, operario_id] = showOperarioLogon;
        return <OperarioLogon callback={callback_operarioLogon} operario_id={operario_id} posicion={posicion} />
    }

    if (estado === 0) {
        header_data = undefined;
        header_original = undefined;
        setEstado(1);
        return null
    }

    const explotacion_predeterminada = config.getAppExplotacion();

    if (header_data === undefined) {
        if (JSON.stringify(data) === '{}') {
            header_data = { ...config.parte_header_default }
            header_data.explotacion_id = explotacion_predeterminada.id
        } else {
            header_data = { ...data }
        }
        header_original = { ...header_data }
    }

    return (
        <Form className="mx-auto" onSubmit={handleSubmit}>
            <div className='card'>
                <div className='card-header'>
                    <b>Vehículo</b>
                </div>
                <div className="card-body">

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Operario</Form.Label>
                            <Form.Select required defaultValue={header_data.conductor_id} onChange={(e) => setShowOperarioLogon([0, e.target.value])}>
                                <option value="">Selecciona operario</option>
                                {config.tUsuarios.map((item, index) => (<option key={index} value={item.id}>{item.nombre}</option>))}
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mt-5 text-center">
                        <Col>
                            <Button variant="danger" onClick={() => cancel_click()}>Cancelar</Button>
                        </Col>
                        <Col>
                            <Button type="submit" variant="primary">Continuar</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal show={showCancel} onHide={() => setShowCancel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancelar</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Hay cambios en la información del parte ¿Desea cancelar las modificaciones y deshacer los cambios?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" style={{ width: 100 }} onClick={() => callback(false, null)}>Si</Button>
                    <Button variant="primary" style={{ width: 100 }} onClick={() => setShowCancel(false)}>No</Button>
                </Modal.Footer>
            </Modal>
        </Form>
    )
}

// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
export function PartesEditar() {

    return "OK"
}