// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
import moment from "moment";
import * as localDB from 'localforage';

export const APP_VERSION = "2023.04.27";

// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
const APP_EXPLOTACION = 'app_explotacion';
const APP_LAST_SYNC = 'app_last_sync';
const APP_PARTES = 'tPartes';

export let tablas = [
    { nombre: 'Usuarios', api: '/operarios/', tabla: 'tUsuarios', estado: 0, error: '' },
    { nombre: 'Vehiculos', api: '/vehiculos/', tabla: 'tVehiculos', estado: 0, error: '' },
    { nombre: 'Vehiculos Tipo', api: '/vehiculos-tipo/', tabla: 'tVehiculosTipos', estado: 0, error: '' },
    { nombre: 'Turnos', api: '/turnos/', tabla: 'tTurnos', estado: 0, error: '' },
    { nombre: 'Explotaciones', api: '/explotaciones/', tabla: 'tExplotaciones', estado: 0, error: '' },
    { nombre: 'Poblaciones', api: '/poblaciones/', tabla: 'tPoblaciones', estado: 0, error: '' },
    { nombre: 'Calles', api: '/calles/', tabla: 'tCalles', estado: 0, error: '' },
    { nombre: 'MantTipo', api: '/mantenimiento-tipos/', tabla: 'tMantTipo', estado: 0, error: '' },
    { nombre: 'Camara modelos', api: '/camara-modelos/', tabla: 'tCamaraModelo', estado: 0, error: '' },
    { nombre: 'Elementos Singulares', api: '/elementos-singulares/', tabla: 'tElementoSingular', estado: 0, error: '' },
];

export const APP_EXPLOTACIONES = [
    { id: 101, user: "besos", nombre: 'Besos' },
    { id: 112, user: 'catcentral', nombre: 'Catalunya Central' },
    { id: 102, user: "ebre", nombre: 'Ebre' },
    { id: 103, user: "girona", nombre: 'Girona' },
    { id: 104, user: "maresme", nombre: 'Maresme' },
    { id: 105, user: "penedes", nombre: 'Penedes' },
    { id: 113, user: 'ponent', nombre: 'Ponent' },
    { id: 106, user: "reus", nombre: 'Reus' },
    { id: 107, user: "testing", nombre: 'Testing' },
    { id: 100, user: 'valles', nombre: 'Valles' }
]

// localStorage
export let app_explotacion = undefined;
export let tPartes = undefined;

// localForage
export let tVehiculos = undefined;
export let tVehiculosTipos = undefined;
export let tTurnos = undefined;
export let tUsuarios = undefined;
export let tExplotaciones = undefined;
export let tPoblaciones = undefined;
export let tCalles = undefined;
export let tMantTipo = undefined;
export let tCamaraModelo = undefined;
export let tElementoSingular = undefined;

export const VEHICULO_PERTIGA = 108;

export const PARTE_TIPO = {
    CAMION: 1,
    CCTV: 2,
    OPERARIO: 3
}

export const tParteTipos = [
    { id: 1, nombre: 'Limpieza'},
    { id: 2, nombre: 'Inspeción'},
    { id: 3, nombre: 'Operario'}
]
export let tMantAcciones = [
    { id:  1, tipo_parte: [1], 'nombre': 'Elementos de Captación' },
    { id:  2, tipo_parte: [1,2], 'nombre': 'Elementos de Red' },
    { id:  3, tipo_parte: [1,2], 'nombre': 'Elementos Singulares' },
    { id:  4, tipo_parte: [1], 'nombre': 'Carga de Agua' },
    { id:  21, tipo_parte: [1], 'nombre': 'Vaciado de Residuos' },
    { id:  5, tipo_parte: [1], 'nombre': 'Depósitos' },
    { id:  6, tipo_parte: [1,2], 'nombre': 'Otros' },       // Productivos
    { id:  7, tipo_parte: [3], 'nombre': 'Otros' },         // No productivos
    { id:  8, tipo_parte: [3], 'nombre': 'Reten' },
    { id:  9, tipo_parte: [3], 'nombre': 'Vaciones' },
    { id:  10, tipo_parte: [3], 'nombre': 'Descansos' },
    { id:  11, tipo_parte: [3], 'nombre': 'Baja laboral' },
];

export const parte_header_default = {
    fecha: new Date(),
    vehiculo_id: 0,
    vehiculo_tipo_id: 0,
    explotacion_id: 0,
    explotacion_origen_id: 0,
    turno_id: 0,
    km: '',
    horas_grupo: '',
    horas_bomba: '',
    horas_depresor: '',

    conductor_id: 0,
    conductor_horas: 0,
    conductor_extras: 0,
    conductor_dietas: 0,
    conductor_1a: false,

    acomp1_id: 0,
    acomp1_horas: 0,
    acomp1_extras: 0,
    acomp1_dietas: 0,
    acomp1_1a: false,

    acomp2_id: 0,
    acomp2_horas: 0,
    acomp2_extras: 0,
    acomp2_dietas: 0,
    acomp2_1a: false,
}

export const parte_body_default = {
    mantenimiento_tipo_id: 0,
    accion_id: 0,

    poblacion_id: 0,
    calle_id: 0,
    esquina_id: 0,

    camara_modelo_id: 0,
    hora_inicial: new Date(),
    hora_final: new Date(),

    // imbornales
    imb_lim: 0,
    reja_lim: 0,
    mlreja_lim: 0,

    // pozos
    pozo_insp: 0,
    pozo_lim: 0,
    mlred_lim: 0,

    // Elemento Singular
    id_esing: 0,
    esing_insp: 0,
    esing_lim: 0,

    // Carga cuba
    carga_cuba: 0,
    carga_residuo: 0,

    // Compartidos con todos las entradas
    galia_ot: '',
    obs: '',
}


export const parte_default = {
    parte_tipo_id: PARTE_TIPO.CAMION,
    parte_finalizado: false,
    header: {},
    body: []
}



// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
export function dictionaryEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
export function getAppExplotacion() {
    const value_raw = localStorage.getItem(APP_EXPLOTACION);
    const value = JSON.parse(value_raw);
    return value
}

export function setAppExplotacion(nombre) {
    const json = JSON.stringify(nombre);
    localStorage.setItem(APP_EXPLOTACION, json);
}

export function getAppExplotacionTable() {
    return APP_EXPLOTACIONES;
}

// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
export function getLastSync() {
    const value_raw = localStorage.getItem(APP_LAST_SYNC);
    let value = moment('1900-01-01 00:00:00')
    if (value_raw) {
        value = JSON.parse(value_raw);
    }
    return value
}

export function setLastSync(fecha) {
    const json = JSON.stringify(fecha);
    localStorage.setItem(APP_LAST_SYNC, json);
}

// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
export function checkDataTables(callback) {
    let todo_ok = true;

    localDB.keys().then( (keys) => {

        for (let i = 0; i < tablas.length; i++) {
            if ( keys.indexOf(tablas[i].tabla) < 0 ) {
                // console.log("Falla ", tablas[i].tabla in keys)
                todo_ok = false;
                break;
            }
        }
        callback(todo_ok);

    }).catch( (error) => {
        // console.log("ERR= ", error)
        callback(false)
    });

}

export function setDataTable(tabla, data) {
    localDB.setItem(tabla, data);
}

export function getDataRowIndex(tabla, indice) {
    const row = tabla[indice];
    return row
}

export function getDataRowByID(tabla, id) {
    const row = tabla.find(item => item.id === parseInt(id));
    return row
}

export function getDataRowField(tabla, id, field = 'nombre') {
    const row = tabla.find(item => item.id === parseInt(id));
    const value = (row) ? row[field] : '-';
    // console.log("V=", value)
    return value
}

export function getDataRowText(tabla, id) {
    const row = tabla.find(item => item.id === parseInt(id));
    const value = (row) ? row.nombre : '(desconocido)';
    // console.log("V=", value)
    return value
}

export function loadDataTables(callback) {
    app_explotacion = getAppExplotacion();

    tPartes = JSON.parse( localStorage.getItem("tPartes") );
    if (tPartes === null) {
        tPartes = []
        localStorage.setItem("tPartes", JSON.stringify(tPartes));
    }

    const p1 = localDB.getItem("tVehiculos").then( (value) => tVehiculos = value );
    const p2 = localDB.getItem("tTurnos").then( (data) => tTurnos = data);
    const p3 = localDB.getItem("tUsuarios").then( (data) => tUsuarios = data);
    const p4 = localDB.getItem("tExplotaciones").then( (data) => tExplotaciones = data);
    const p5 = localDB.getItem("tPoblaciones").then( (data) => tPoblaciones = data);
    const p6 = localDB.getItem("tCalles").then( (data) => tCalles = data);
    const p7 = localDB.getItem("tMantTipo").then( (data) => tMantTipo = data);
    const p8 = localDB.getItem("tCamaraModelo").then( (data) => tCamaraModelo = data);
    const p9 = localDB.getItem("tElementoSingular").then( (data) => tElementoSingular = data);
    const p10 = localDB.getItem("tVehiculosTipos").then( (value) => tVehiculosTipos = value );

    Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10]).then( () => {
        callback(true)
    }).catch( (error) => {
        // console.log("ERR=", error)
        callback(false)
    })
}

export const deleteDataTables = (callback) => {
    sessionStorage.clear();
    localStorage.clear();

    localDB.clear().then( () => {
        callback(true)
    }).catch(function(err) {
        // console.log(err);
        callback(false)
    });
}

// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
export function getPartes() {
    const value_raw = localStorage.getItem(APP_PARTES);
    const value = JSON.parse(value_raw);
    return value
}

export function setPartes(data) {
    const json = JSON.stringify(data);
    localStorage.setItem(APP_PARTES, json);
}

export function getPartesCount() {
    const value_raw = localStorage.getItem(APP_PARTES);
    const value = JSON.parse(value_raw);
    const num = (value) ? value.length : 0
    return num
}

export function checkPartesFinalizados() {
    let result = true;

    for(let i=0; i< tPartes.length; i++) {
        const row = tPartes[i];
        if ( row.parte_tipo_id != 3) {
            result = result & row.parte_finalizado;
        }
    }

    return result;
}
// --------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------
export function redondeaFechaHora(fecha) {
    // Recibe moment(), contestar moment()

    const minutos = fecha.minute() % 15;
    const segundos = fecha.seconds();
    return fecha.add(-minutos, "minutes").add(-segundos, "seconds")
}

export function printHorasMinutos(f1, f2) {
    const totales = moment(f2).diff(moment(f1), "minutes", true);
    const minutos = (totales % 60).toString().padStart(2,'0');
    const horas =  parseInt(totales / 60);

    return `${horas}:${minutos}`;
}

export function formatMinutos(totales) {
    const minutos = (totales % 60).toString().padStart(2,'0');
    const horas =  parseInt(totales / 60);

    return `${horas}:${minutos}`;
}

export function round_medias_horas(horas) {
    let result = 0;

    for(result = 0; result <=8; result+=0.5) {
        if ( result >= horas ) {
            break;
        }
    }
    return result
}